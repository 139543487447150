<i18n>
{
  "de": {
    "header": "Renovationsstandards",
    "text": "Einstellungen für die Renovationsstandards"
  }
}
</i18n>

<template>
  <DetailListItem :title="$t('header')" expandable @expand="onModalOpen">
    <span>{{ $t('text') }}</span>
    <template #modal>
      <RenovationStandardsModal v-if="modalOpen" :building="building" :portfolio="portfolio" @close="onModalClose" />
    </template>
  </DetailListItem>
</template>

<script>
import DetailListItem from '@/components/shared/lists/DetailListItem.vue'
import RenovationStandardsModal from '@/components/building/overview/RenovationStandardsModal.vue'

export default {
  components: {
    DetailListItem,
    RenovationStandardsModal,
  },

  props: {
    building: {
      type: Object,
      required: true,
    },
    portfolio: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      modalOpen: false,
    }
  },

  methods: {
    onModalOpen() {
      this.modalOpen = true
    },

    onModalClose() {
      this.modalOpen = false
    },
  },
}
</script>
