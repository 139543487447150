<i18n>
{
  "de": {
    "activeFromLabel": "Liegenschaft aktiv seit",
    "activeToLabel": "Liegenschaft aktiv bis",
    "sourceBuildingYear": "Quelle: Baujahr",
    "activeFromInfoBox": "Ab diesem Jahr trägt die Liegenschaft statistisch zum Portfolio bei. Wenn dieses Feld leer gelassen wird, wird das Baujahr übernommen.",
    "activeToInfoBox": "Bis zu diesem Jahr trägt die Liegenschaft statistisch zum Portfolio bei.",
    "invalidSpanErrorMessage": "Aktiv seit und aktiv bis müssen eine gültige Zeitspanne bilden"
  }
}
</i18n>

<template>
  <EditContainer
    :bind-form-state="formState"
    :allow-edit="getBuildingPermission('EDIT_ACTIVE')"
    class="c-edit-active"
    @container="onFormEvent"
  >
    <FormRow :label="$t('activeFromLabel')" :info-text="$t('activeFromInfoBox')">
      <NumericInput
        v-if="isEditing"
        v-model="model.active_from"
        noFormatting
        int
        optional
        :min="this.buildingYear ? this.buildingYear : 0"
        :max="2100"
        :invalidValues="invalidActiveFrom"
        :invalidValuesErrorMessage="$t('invalidSpanErrorMessage')"
        :edit="isEditing"
        class="limit-width"
        @validation="onValidation($event, 'active-from')"
      />
      <div v-else-if="model.active_from">
        {{ model.active_from }}
      </div>
      <div v-else-if="buildingYear">
        {{ buildingYear }}
        <div class="source-info">{{ $t('sourceBuildingYear') }}</div>
      </div>
      <div v-else class="placeholder">-</div>
    </FormRow>
    <FormRow :label="$t('activeToLabel')" :info-text="$t('activeToInfoBox')">
      <NumericInput
        v-if="isEditing"
        v-model="model.active_to"
        noFormatting
        int
        optional
        :min="0"
        :max="2100"
        :invalidValues="invalidActiveTo"
        :invalidValuesErrorMessage="$t('invalidSpanErrorMessage')"
        :edit="isEditing"
        class="limit-width"
        @validation="onValidation($event, 'active-to')"
      />
      <div v-else :class="{ placeholder: !model.active_to }">
        {{ model.active_to || '-' }}
      </div>
    </FormRow>
  </EditContainer>
</template>

<script>
import { mapActions } from 'vuex'

import ApiFormMixin from '@/components/shared/forms/ApiFormMixin.vue'

import EditContainer from '@/components/shared/forms/EditContainer.vue'
import NumericInput from '@/components/shared/forms/NumericInput.vue'
import FormRow from '@/components/shared/forms/FormRow.vue'

export default {
  mixins: [
    ApiFormMixin,
    /* ApiFormMixin:
      - Requires implementation of the following methods: resetModel, saveHandler, isValid (optional)
      - Provides: model, isValid, isEditing, formState, onFormEvent, onValidation,
                  getBuildingPermission, getPortfolioPermission
    */
  ],

  components: {
    EditContainer,
    NumericInput,
    FormRow,
  },

  props: {
    building: {
      type: Object,
      required: true,
    },
  },

  computed: {
    buildingYear() {
      return this.building?.initial_state?.result &&
        this.building?.initial_state?.result?.envelope?.building_year?.value
        ? this.building.initial_state.result.envelope.building_year.value
        : null
    },

    invalidActiveFrom() {
      return [
        ...(this.buildingYear ? [...Array(this.buildingYear).keys()] : []),
        ...(this.model.active_to && Number.isInteger(Number(this.model.active_to))
          ? [...Array(2100 - Math.min(this.model.active_to, 2100)).keys()].map((x) => x + this.model.active_to + 1)
          : []),
      ]
    },

    invalidActiveTo() {
      return this.model.active_from && Number.isInteger(Number(this.model.active_from))
        ? [...Array(Math.min(this.model.active_from, 2100)).keys()]
        : this.buildingYear
        ? [...Array(this.buildingYear).keys()]
        : []
    },
  },

  watch: {
    building() {
      this.resetModel()
    },

    isEditing() {
      this.resetModel()
    },
  },

  mounted() {
    this.resetModel()
  },

  methods: {
    ...mapActions({
      updateBuilding: 'building/updateBuilding',
    }),

    resetModel() {
      this.model = {
        active_from: this.building.active_from,
        active_to: this.building.active_to,
      }
    },

    async saveHandler(model) {
      await this.updateBuilding({
        id: this.building.id,
        active_from: model.active_from,
        active_to: model.active_to,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.c-edit-active {
  & .limit-width {
    max-width: 150px;
  }

  & .source-info {
    color: var(--secondary-text-color);
    font-size: var(--font-xs);
    line-height: 16px;
  }

  & .placeholder {
    opacity: 0.5;
  }
}
</style>
