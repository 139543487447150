<i18n>
{
  "de": {
    "totalLabel": "Modellwert",
    "measuredTitle": "Messwerte",
    "histogramTitle": "Im Vergleich mit anderen Liegenschaften",
    "byCarrier": "Nach Energieträger",
    "byUsage": "Nach Verwendungszweck",
    "currentTitle": "Aktuelle Kennzahlen",
    "projectionTitle": "Absenkpfad"
  }
}
</i18n>

<template>
  <Modal class="c-kpi-modal" wide @close="$emit('close', $event)">
    <template #header>
      <h2>{{ getIndicatorName }}</h2>
      <h3>{{ building.identifier }} / {{ building.description }}</h3>
    </template>
    <Tabs :group="`${kpiType}-kpi-modal-tabs`">
      <Tab :title="$t('currentTitle')">
        <div class="current-kpis">
          <div>
            <h3>{{ $t('byCarrier') }}</h3>
            <KpiPieChart
              key="s1e_by_carrier"
              :kpi-type="kpiType"
              :group-by-type="'carrier'"
              :portfolio="portfolio"
              :chart-data="filteredChartData"
              class="pie-chart"
            />
          </div>
          <div>
            <h3>{{ $t('byUsage') }}</h3>
            <KpiPieChart
              key="s1e_by_usage"
              :kpi-type="kpiType"
              :group-by-type="'usage'"
              :portfolio="portfolio"
              :chart-data="filteredChartData"
              class="pie-chart"
            />
          </div>
          <div>
            <h3>{{ $t('histogramTitle') }}</h3>
            <HistogramChart
              :key="`${kpiType}_histogram`"
              :group-by-key="kpiType"
              area-specific
              :highlight-value="building.kpi[kpiType] / building.kpi.energy_area"
              :chart-data="chartData"
              class="histogram-chart"
            />
          </div>
        </div>
      </Tab>
      <Tab :title="$t('measuredTitle')">
        <MeasuredKpiChart
          :kpi-type="kpiType"
          class="kpi-chart"
          :portfolio="portfolio"
          :query="{ building_id: this.building.id }"
        />
      </Tab>
      <Tab :title="$t('projectionTitle')">
        <ScenarioPicker v-model="selectedScenario" :portfolio="portfolio" />
        <h4>{{ $t('byCarrier') }}</h4>
        <KpiByCarrierChart
          v-if="selectedScenario"
          :kpi-type="kpiType"
          class="kpi-chart"
          :scenario="selectedScenario"
          :portfolio="portfolio"
          :query="{ building_id: this.building.id }"
        />
        <h4>{{ $t('byUsage') }}</h4>
        <KpiByUsageChart
          v-if="selectedScenario"
          :kpi-type="kpiType"
          class="kpi-chart"
          :scenario="selectedScenario"
          :portfolio="portfolio"
          :query="{ building_id: this.building.id }"
        />
      </Tab>
    </Tabs>
  </Modal>
</template>

<script>
import { mapGetters } from 'vuex'

import Tabs from '@/components/shared/Tabs.vue'
import Tab from '@/components/shared/Tab.vue'
import ScenarioPicker from '@/components/shared/ScenarioPicker.vue'
import Modal from '@/components/shared/Modal.vue'
import KpiByUsageChart from '@/components/report/charts/KpiByUsageChart.vue'
import KpiByCarrierChart from '@/components/report/charts/KpiByCarrierChart.vue'
import KpiPieChart from '@/components/portfolio/kpi/charts/KpiPieChart.vue'
import MeasuredKpiChart from '@/components/report/charts/MeasuredKpiChart.vue'
import HistogramChart from '@/components/portfolio/kpi/charts/HistogramChart.vue'

export default {
  components: {
    ScenarioPicker,
    Modal,
    KpiByCarrierChart,
    KpiByUsageChart,
    HistogramChart,
    MeasuredKpiChart,
    KpiPieChart,
    Tab,
    Tabs,
  },

  props: {
    portfolio: {
      type: Object,
      required: true,
    },
    building: {
      type: Object,
      required: true,
    },
    kpiType: {
      type: String, // pe, per, s1e, s2e, s12e, ...
      required: true,
    },
    chartData: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      selectedScenario: null,
    }
  },

  computed: {
    ...mapGetters({
      __getEnhancedSustainabilityIndicator: 'portfolio/getEnhancedSustainabilityIndicatorByIdentifier',
    }),

    sustainabilityIndicator() {
      return this.__getEnhancedSustainabilityIndicator(this.kpiType)
    },

    getIndicatorName() {
      if (!this.sustainabilityIndicator) {
        return this.kpiType
      }
      return this.sustainabilityIndicator.name
    },

    filteredChartData() {
      return this.chartData.filter((b) => b.id === this.building.id)
    },
  },
}
</script>

<style lang="scss">
.c-kpi-modal {
  & .current-kpis {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: var(--spacing-m);
  }
  & .kpi-chart .chart-container {
    height: 270px;
  }
  & .histogram-chart {
    height: 220px;
  }
  & .pie-chart > div {
    height: 200px;
  }
  & .energy-label {
    display: inline;
  }
}
</style>
