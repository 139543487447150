<i18n>
{
  "de": {
    "yLabel": "Kapital (CHF)",
    "balance": "Kapitalentwicklung",
    "types": {
      "subsidies": "Einmalvergütung",
      "operation_costs": "Betriebskosten",
      "revenue_ownuse": "Reduktion Netzbezug",
      "revenue_feedin": "Rückspeisung",
      "investment": "Investitionskosten"
    }
  }
}
</i18n>

<template>
  <ExportableBarChart class="npv-chart" :chart-data="chartData" :options="options" />
</template>

<script>
import ChartOptionsMixin from '@/pages/vuex-mixins/ChartOptionsMixin.vue'

import ExportableBarChart from '@/components/shared/charts/ExportableBarChart.vue'

import colorPalettes from '@/services/color-palettes.js'

export default {
  mixins: [ChartOptionsMixin],

  components: {
    ExportableBarChart,
  },

  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  computed: {
    options() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxis: {
            stacked: true,
          },
          yAxis: {
            stacked: true,
            beginAtZero: true,
            ticks: {
              callback: (value) => this.formatNumber(value, 0),
            },
            title: {
              display: true,
              text: this.$t('yLabel'),
            },
          },
        },
        plugins: {
          tooltip: {
            mode: 'index',
            callbacks: {
              label: (item) => this.getTooltipLabel(item, { unit: 'CHF' }),
            },
          },
          legend: {
            position: 'bottom',
            align: 'start',
            reverse: true,
          },
        },
      }
    },

    chartData() {
      let datasets = []
      datasets.push({
        label: this.$t(`balance`),
        lineTension: 0.1,
        borderWidth: 0,
        pointRadius: 0,
        pointHitRadius: 10,
        data: this.data.npv,
        backgroundColor: colorPalettes.pvNpvColors['cumulative_total'],
        borderColor: colorPalettes.pvNpvColors['cumulative_total'],
        stack: 'CAPITAL',
      })
      for (const type in this.data.cashflow) {
        if (Object.prototype.hasOwnProperty.call(this.data.cashflow, type)) {
          datasets.push({
            label: this.$t(`types.${type}`),
            lineTension: 0.1,
            borderWidth: 0,
            pointRadius: 0,
            pointHitRadius: 10,
            data: this.data.cashflow[type],
            backgroundColor: colorPalettes.pvNpvColors[type],
            borderColor: colorPalettes.pvNpvColors[type],
            stack: 'CASHFLOW',
          })
        }
      }
      return {
        labels: this.data.years,
        datasets: datasets,
      }
    },
  },
}
</script>
