<i18n>
{
  "de": {
    "modalTitle": "Stromproduktion",
    "productionTotalLabel": "Modellierte Stromproduktion",
    "productionByUsage": "Nach Verwendung",
    "ownUse": "Eigenverbrauch",
    "currentTitle": "Aktuelle Kennzahlen",
    "histogramTitle": "Im Vergleich mit anderen Liegenschaften",
    "measuredTitle": "Messwerte",
    "projectionTitle": "Absenkpfad"
  }
}
</i18n>

<template>
  <Modal class="electricity-production-modal" wide @close="$emit('close', $event)">
    <template #header>
      <h2>{{ $t('modalTitle') }}</h2>
      <h3>{{ building.identifier }} / {{ building.description }}</h3>
    </template>
    <Tabs group="electricity-production-modal-tabs">
      <Tab :title="$t('currentTitle')">
        <div class="current-kpis">
          <DetailList class="kpi-overview">
            <DetailListItem :title="$t('productionTotalLabel')">
              <div class="three-columns">
                <span>{{ formatNumber(building.kpi.el_production / 1000, 1) }} MWh</span>
                <span></span>
                <span v-if="building.kpi.el_production > 0"
                  >{{ $t('ownUse') }}:
                  {{ formatNumber((building.kpi.el_ownuse / building.kpi.el_production) * 100, 0) }} %</span
                >
                <span v-else></span>
              </div>
            </DetailListItem>
          </DetailList>
          <div>
            <h3>{{ $t('histogramTitle') }}</h3>
            <HistogramChart
              :key="'el_production'"
              :group-by-key="'el_production'"
              area-specific
              :highlight-value="building.kpi.el_production / building.kpi.energy_area"
              :chart-data="chartData"
              class="histogram-chart"
            />
          </div>
        </div>
      </Tab>
      <Tab :title="$t('measuredTitle')">
        <MeasuredKpiChart
          :kpi-type="'ee'"
          class="demand-chart"
          :portfolio="portfolio"
          :query="{ building_id: this.building.id }"
        />
      </Tab>
      <Tab :title="$t('projectionTitle')">
        <scenario-picker v-model="selectedScenario" :portfolio="portfolio" />
        <h4>{{ $t('productionByUsage') }}</h4>
        <OwnUseChart
          v-if="selectedScenario"
          class="production-chart"
          :scenario="selectedScenario"
          :portfolio="portfolio"
          :query="{ building_id: this.building.id }"
        />
      </Tab>
    </Tabs>
  </Modal>
</template>

<script>
import Tabs from '@/components/shared/Tabs.vue'
import Tab from '@/components/shared/Tab.vue'
import ScenarioPicker from '@/components/shared/ScenarioPicker.vue'
import Modal from '@/components/shared/Modal.vue'
import DetailList from '@/components/shared/lists/DetailList.vue'
import DetailListItem from '@/components/shared/lists/DetailListItem.vue'
import OwnUseChart from '@/components/report/charts/OwnUseChart.vue'
import MeasuredKpiChart from '@/components/report/charts/MeasuredKpiChart.vue'
import HistogramChart from '@/components/portfolio/kpi/charts/HistogramChart.vue'

export default {
  components: {
    ScenarioPicker,
    Modal,
    DetailList,
    DetailListItem,
    OwnUseChart,
    HistogramChart,
    MeasuredKpiChart,
    Tabs,
    Tab,
  },

  props: {
    portfolio: {
      type: Object,
      required: true,
    },
    building: {
      type: Object,
    },
    chartData: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      selectedScenario: null,
    }
  },
}
</script>

<style>
.electricity-production-modal .production-chart .chart-container {
  min-width: 1000px;
  height: 300px;
}
.electricity-production-modal .current-kpis {
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-gap: var(--spacing-m);
}
.electricity-production-modal .histogram-chart {
  height: 220px;
}
</style>
