<i18n>
{
  "de": {
    "title": "Machbarkeit\u00ads- und Potenzialabklärungen"
  }
}
</i18n>

<template>
  <Card>
    <CardHeader :title="$t('title')" />
    <CardContent>
      <List divider>
        <GeothermalSummaryRow v-if="building.geothermal" :building="building" />
        <EnergyGridsSummaryRow v-if="building.energy_grids" :building="building" />
        <PvPotentialRow :building="building" />
        <ElectricityUtilitySummaryRow :building="building" />
      </List>
    </CardContent>
  </Card>
</template>

<script>
import Card from '@/components/cui/surfaces/Card.vue'
import CardHeader from '@/components/cui/surfaces/CardHeader.vue'
import CardContent from '@/components/cui/surfaces/CardContent.vue'
import List from '@/components/cui/data-display/List.vue'

import GeothermalSummaryRow from '@/components/building/feasibility/GeothermalSummaryRow.vue'
import EnergyGridsSummaryRow from '@/components/building/feasibility/EnergyGridsSummaryRow.vue'
import PvPotentialRow from '@/components/building/feasibility/PvPotentialRow.vue'
import ElectricityUtilitySummaryRow from '@/components/building/feasibility/ElectricityUtilitySummaryRow.vue'

export default {
  components: {
    Card,
    CardHeader,
    CardContent,
    List,
    GeothermalSummaryRow,
    EnergyGridsSummaryRow,
    PvPotentialRow,
    ElectricityUtilitySummaryRow,
  },

  props: {
    building: {
      type: Object,
      required: true,
    },
  },
}
</script>
