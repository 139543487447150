<i18n>
{
  "de": {
    "energyGridRenovation": "Änderungen Netzanschluss",
    "heatingReplacement": "Heizungsersatz",
    "pvInstallation": "Photovoltaikanlage",
    "changeZones": "Änderung von Nutzung und Flächen",
    "windowsReplacement": "Fensterersatz",
    "wallsRenovation": "Sanierung Fassade",
    "roofRenovation": "Sanierung Dach",
    "basementRenovation": "Sanierung Kellerdecke",
    "noSectionsWarning": "Keine passende Fläche für PV-Anlage gefunden."
  }
}
</i18n>

<template>
  <span class="measure-summary">
    <div v-if="measure.actions.zones !== null" class="partial-measure">
      <div>{{ $t('changeZones') }}</div>
      <div></div>
    </div>
    <div v-if="measure.actions.pv !== null && measure.next_state.pv.sections.length > 0" class="partial-measure">
      <div>{{ $t('pvInstallation') }} {{ formatNumber(pvCapacity, 0) }} kW</div>
      <div>{{ formatNumber(measure.costs.pv.costs, -3) }} CHF</div>
    </div>
    <div v-if="measure.actions.pv !== null && measure.next_state.pv.sections.length === 0" class="partial-measure">
      <div class="partial-measure__warning">
        <span> {{ $t('pvInstallation') }} {{ formatNumber(0, 0) }} kW </span>
        <DataWarning>{{ $t('noSectionsWarning') }}</DataWarning>
      </div>
      <div>{{ formatNumber(0, -3) }} CHF</div>
    </div>
    <div v-if="measure.actions.heating !== null" class="partial-measure">
      <div>
        {{ $t('heatingReplacement') }}
        {{ getHeatingTypeName(measure.actions.heating.heaters[0].heating_type_id) }}
      </div>
      <div>{{ formatNumber(measure.costs.heating.costs, -3) }} CHF</div>
    </div>
    <div
      v-if="
        measure.actions.envelope !== null &&
        measure.actions.envelope.windows !== null &&
        measure.actions.envelope.windows.standard !== 'NONE'
      "
      class="partial-measure"
    >
      <div>{{ $t('windowsReplacement') }}</div>
      <div>{{ formatNumber(measure.costs.windows.costs, -3) }} CHF</div>
    </div>
    <div
      v-if="
        measure.actions.envelope !== null &&
        measure.actions.envelope.walls !== null &&
        measure.actions.envelope.walls.standard !== 'NONE'
      "
      class="partial-measure"
    >
      <div>{{ $t('wallsRenovation') }}</div>
      <div>{{ formatNumber(measure.costs.walls.costs, -3) }} CHF</div>
    </div>
    <div
      v-if="
        measure.actions.envelope !== null &&
        measure.actions.envelope.roof !== null &&
        measure.actions.envelope.roof.standard !== 'NONE'
      "
      class="partial-measure"
    >
      <div>{{ $t('roofRenovation') }}</div>
      <div>{{ formatNumber(measure.costs.roof.costs, -3) }} CHF</div>
    </div>
    <div
      v-if="
        measure.actions.envelope !== null &&
        measure.actions.envelope.basement !== null &&
        measure.actions.envelope.basement.standard !== 'NONE'
      "
      class="partial-measure"
    >
      <div>{{ $t('basementRenovation') }}</div>
      <div>{{ formatNumber(measure.costs.basement.costs, -3) }} CHF</div>
    </div>
    <div v-if="measure.actions.electricity !== null" class="partial-measure">
      <div>{{ $t('energyGridRenovation') }}</div>
      <div>{{ formatNumber(measure.costs.electricity.costs, -3) }} CHF</div>
    </div>
  </span>
</template>

<script>
import DataWarning from '@/components/shared/DataWarning.vue'

export default {
  components: {
    DataWarning,
  },

  props: {
    portfolio: {
      type: Object,
      required: true,
    },
    measure: {
      type: Object,
      required: true,
    },
  },

  computed: {
    pvCapacity() {
      return this.measure.pv_sections.reduce((pv, cv) => pv + cv.capacity, 0)
    },
  },

  methods: {
    getHeatingTypeName(heatingTypeId) {
      const heatingType = this.portfolio.heating_types.find((ht) => ht.name === heatingTypeId)
      if (heatingType) {
        if (heatingType.default) {
          return this.$t(`_heatingTypes.${heatingType.name}`)
        } else {
          return heatingType.name
        }
      } else {
        return this.$t('_heatingTypes.UNKNOWN')
      }
    },

    getEnergyGridName(energyGridId) {
      const energyCarrier = this.portfolio.energy_carriers.find((ec) => ec.id === energyGridId)
      if (energyCarrier && energyCarrier.name !== 'ELECTRICITY_GRID') {
        return energyCarrier.name
      } else {
        return this.$t('_energyCarriers.ELECTRICITY_GRID')
      }
    },
  },
}
</script>

<style lang="scss">
.measure-summary {
  display: grid;
  gap: var(--spacing-s);
  grid-template-columns: 1fr 1fr;

  & .partial-measure {
    display: contents;

    & .partial-measure__warning {
      display: flex;
      align-items: center;
      gap: var(--spacing-xs);
    }
  }

  & .measure-remarks {
    font-size: var(--font-xs);
  }
}
</style>
