<i18n>
{
  "de": {
    "header": "Nutzenergie Warmwasser",
    "highCalibrationFactorWarningTitle": "Effektiver Energieverbrauch höher als erwartet",
    "highCalibrationFactorWarning": "Der effektive Energieverbrauch liegt deutlich über dem modellierten Energiebedarf. Für mögliche Ursachen siehe Kalibrierung Wärmeerzeugung.",
    "lowCalibrationFactorWarningTitle": "Effektiver Energieverbrauch tiefer als erwartet",
    "lowCalibrationFactorWarning": "Der effektive Energieverbrauch liegt deutlich unter dem modellierten Energiebedarf. Für mögliche Ursachen siehe Kalibrierung Wärmeerzeugung."
  }
}
</i18n>

<template>
  <DetailListItem :title="$t('header')" expandable @expand="onModalOpen">
    <div class="two-columns">
      <div class="value">
        <span>{{ formatNumber(building.kpi.dhw_demand / 1000, 1) }} MWh</span>
        <template v-if="building.kpi.dhw_calibration > 1.667">
          <DataWarning :title="$t('highCalibrationFactorWarningTitle')">
            <span v-html="$t('highCalibrationFactorWarning')" />
          </DataWarning>
        </template>
        <template v-else-if="building.kpi.dhw_calibration < 0.6">
          <DataWarning :title="$t('lowCalibrationFactorWarningTitle')">
            <span v-html="$t('lowCalibrationFactorWarning')" />
          </DataWarning>
        </template>
      </div>
      <span>{{ formatNumber(building.kpi.dhw_demand / building.kpi.energy_area, 1) }} kWh/m²</span>
    </div>
    <template #modal>
      <DhwModal v-if="modalOpen" :building="building" @close="onModalClose" />
    </template>
  </DetailListItem>
</template>

<script>
import DetailListItem from '@/components/shared/lists/DetailListItem.vue'
import DataWarning from '@/components/shared/DataWarning.vue'
import DhwModal from '@/components/building/kpi/DhwModal.vue'

export default {
  components: {
    DetailListItem,
    DataWarning,
    DhwModal,
  },

  props: {
    building: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      modalOpen: false,
    }
  },

  methods: {
    onModalOpen() {
      this.modalOpen = true
    },

    onModalClose() {
      this.modalOpen = false
    },
  },
}
</script>

<style lang="scss" scoped>
.value {
  display: flex;
  flex-direction: row;
  align-items: center;

  & > * + * {
    margin-left: var(--spacing-xs);
  }
}
</style>
