<i18n>
{
  "de": {
    "confirmDeleteMeasure": "Soll diese Massnahme endgültig gelöscht werden?",
    "confirmCompleteMeasure": "Soll diese Massnahme wirklich als umgesetzt markiert werden? Dies kann nicht rückgängig gemacht werden.",
    "completeMeasureTooltip": "Massnahme als 'Umgesetzt' markieren",
    "detailsButtonTooltip": "Auswirkungen der Massnahme anzeigen",
    "scenarioMeasure": "Szenario",
    "scenarioEditedMeasure": "Szenario editiert",
    "plannedMeasure": "Geplant",
    "completedMeasure": "Umgesetzt",
    "savingsExplanation": "Gesamt / Massnahme",
    "peRatioExplanation": "Gesamtenergieeffizienz nach SIA 2031:2016",
    "rhRatioExplanation": "Effizienzklasse Gebäudehülle nach SIA 2031:2016"
  }
}
</i18n>
<template>
  <li class="measure-row">
    <section class="measure-table">
      <!-- Measure Scenario Type -->
      <template>
        <span v-if="measure.phase === 'SCENARIO'" class="measure-phase">
          <ScenarioIcon :color="scenario.color" :title="$t('_measurePhases.SCENARIO')" />
          {{ $t('scenarioMeasure') }}
        </span>
        <span v-if="measure.phase === 'SCENARIO_EDITED'" class="measure-phase">
          <ScenarioIcon :color="'black'" :title="$t('_measurePhases.SCENARIO_EDITED')" />
          {{ $t('scenarioEditedMeasure') }}
        </span>
        <span v-if="measure.phase === 'PLANNED'" class="measure-phase">
          <img src="/icons/calendar.svg" :title="$t('_measurePhases.PLANNED')" />
          {{ $t('plannedMeasure') }}
        </span>
        <span v-if="measure.phase === 'COMPLETED'" class="measure-phase">
          <img src="/icons/check.svg" :title="$t('_measurePhases.COMPLETED')" />
          {{ $t('completedMeasure') }}
        </span>
      </template>

      <!-- Year -->
      <span>{{ measure.year }}</span>

      <!-- Measures Summary -->
      <MeasureSummary :measure="measure" :portfolio="portfolio" />

      <!-- CO2 -->
      <VPopover trigger="hover" placement="right">
        <span v-if="measure.kpi && measure.kpi.s12e && lastKpi && lastKpi.s12e">
          {{ formatNumber(measure.kpi.s12e / 1000, 1) }} t CO₂e
          <span>
            (<span v-if="measure.kpi.s12e === building.kpi.s12e">-0 %</span>
            <span v-else-if="measure.kpi.s12e > building.kpi.s12e"
              >+{{ formatNumber(((measure.kpi.s12e - building.kpi.s12e) / building.kpi.s12e) * 100, 0) }}%</span
            >
            <span v-else
              >{{ formatNumber(((measure.kpi.s12e - building.kpi.s12e) / building.kpi.s12e) * 100, 0) }}%</span
            >
            / <span v-if="measure.kpi.s12e === lastKpi.s12e">-0 %</span>
            <span v-else-if="measure.kpi.s12e > lastKpi.s12e"
              >+{{ formatNumber(((measure.kpi.s12e - lastKpi.s12e) / lastKpi.s12e) * 100, 0) }}%</span
            >
            <span v-else>{{ formatNumber(((measure.kpi.s12e - lastKpi.s12e) / lastKpi.s12e) * 100, 0) }}%</span>)
          </span>
        </span>
        <span v-else>-</span>
        <template slot="popover">
          <main>{{ $t('savingsExplanation') }}</main>
        </template>
      </VPopover>

      <!-- Energy Label: Primary energy -->
      <VPopover trigger="hover" placement="right">
        <EnergyLabel v-if="peRatio !== null" :value="peRatio" />
        <span v-else>-</span>
        <template slot="popover">
          <main>{{ $t('peRatioExplanation') }}</main>
        </template>
      </VPopover>

      <!-- Energy Label: Heating -->
      <VPopover trigger="hover" placement="right">
        <EnergyLabel v-if="rhRatio !== null" :value="rhRatio" />
        <span v-else>-</span>
        <template slot="popover">
          <main>{{ $t('rhRatioExplanation') }}</main>
        </template>
      </VPopover>

      <!-- Buttons -->
      <ListButtonWrapper>
        <ListCompleteButton v-if="allowComplete" :tooltip="$t('completeMeasureTooltip')" @click="onCompleteMeasure" />
        <ListDeleteButton v-if="allowDelete" @click="onDeleteMeasure" />
        <ListDetailsButton :tooltip="$t('detailsButtonTooltip')" @click="openViewModal" />
        <ListShowOrEditButton v-if="showEdit" :allow-edit="allowEdit" @click="openEditModal" />
      </ListButtonWrapper>
    </section>

    <!-- Modals -->
    <ViewMeasureModal v-if="viewModalOpen" :measure="measure" :last-kpi="lastKpi" @close="closeViewModal" />
    <EditMeasureModal
      v-if="editModalOpen"
      :building="building"
      :portfolio="portfolio"
      :selected-scenario="selectedScenario"
      :measure="measure"
      @close="closeEditModal"
    />
  </li>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { VPopover } from 'v-tooltip'

import ListButtonWrapper from '@/components/shared/lists/ListButtonWrapper.vue'
import ListDeleteButton from '@/components/shared/lists/ListDeleteButton.vue'
import ListDetailsButton from '@/components/shared/lists/ListDetailsButton.vue'
import EditMeasureModal from '@/components/building/measures/edit/EditMeasureModal.vue'
import ViewMeasureModal from '@/components/building/measures/ViewMeasureModal.vue'
import MeasureSummary from '@/components/building/measures/MeasureSummary.vue'
import ScenarioIcon from '@/components/shared/ScenarioIcon.vue'
import EnergyLabel from '@/components/shared/EnergyLabel.vue'
import ListCompleteButton from '@/components/shared/lists/ListCompleteButton.vue'
import ListShowOrEditButton from '@/components/shared/lists/ListShowOrEditButton.vue'

export default {
  components: {
    ListShowOrEditButton,
    ListCompleteButton,
    VPopover,
    ListButtonWrapper,
    ListDetailsButton,
    ListDeleteButton,
    EditMeasureModal,
    ViewMeasureModal,
    MeasureSummary,
    ScenarioIcon,
    EnergyLabel,
  },

  props: {
    selectedScenario: {
      type: Object,
      required: true,
    },
    portfolio: {
      type: Object,
    },
    building: {
      type: Object,
      required: true,
    },
    measure: {
      type: Object,
      required: true,
    },
    lastKpi: {
      type: Object,
    },
  },

  data() {
    return {
      editModalOpen: false,
      viewModalOpen: false,
    }
  },

  computed: {
    ...mapGetters({
      getBuildingPermission: 'permissions/getBuildingPermission',
    }),

    showEdit() {
      return this.getBuildingPermission('EDIT_MEASURES') || this.measure.phase !== 'SCENARIO'
    },

    allowEdit() {
      return this.getBuildingPermission('EDIT_MEASURES')
    },

    allowComplete() {
      return this.measure.phase !== 'COMPLETED' && !this.allowEdit && this.getBuildingPermission('COMPLETE_MEASURES')
    },

    allowDelete() {
      return this.measure.phase !== 'SCENARIO' && this.getBuildingPermission('DELETE_MEASURES')
    },

    scenario() {
      return this.portfolio.scenarios.find((s) => s.id === this.measure.scenario_id)
    },

    rhRatio() {
      return this.measure.kpi && this.measure.kpi.rh_demand / this.measure.kpi.energy_area / this.measure.kpi.rh_limit
    },

    peRatio() {
      return this.measure.kpi && this.measure.kpi.pe / this.measure.kpi.energy_area / this.measure.kpi.pe_ref
    },
  },

  methods: {
    ...mapActions({
      completeMeasure: 'building/completeMeasure',
      deleteMeasure: 'building/deleteMeasure',
    }),

    openEditModal() {
      this.editModalOpen = true
    },

    closeEditModal() {
      this.editModalOpen = false
    },

    openViewModal() {
      this.viewModalOpen = true
    },

    closeViewModal() {
      this.viewModalOpen = false
    },
    async onCompleteMeasure() {
      if (confirm(this.$t('confirmCompleteMeasure'))) {
        try {
          this.error = null
          await this.completeMeasure(this.measure)
          this.error = null
        } catch (error) {
          this.error = error
        }
      }
    },
    async onDeleteMeasure() {
      if (confirm(this.$t('confirmDeleteMeasure'))) {
        try {
          this.error = null
          await this.deleteMeasure(this.measure)
          this.error = null
        } catch (error) {
          this.error = error
        }
      }
    },
  },
}
</script>

<style>
.measure-row .measure-phase img {
  vertical-align: middle;
  opacity: 0.7;
}
.measure-row .measure-table {
  display: contents;
}
</style>
