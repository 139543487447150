<!--
Edit form for HeaterItemSchema (many=true)
-->
<i18n>
{
  "de": {
    "heatingTypeTitle": "Typ",
    "rhShareTitle": "Anteil Raumwärme",
    "dhwShareTitle": "Anteil Warmwasser",
    "energyCalibration": "Modellkalibrierung",
    "remarks": "Bemerkungen",
    "addHeatingButton": "Wärmeerzeuger hinzufügen",
    "noHeatingDefined": "Keine Wärmeerzeuger",
    "endEnergyTitle": "Modellwert",
    "yearLabel": "Jahr",
    "measuredTitle": "Messwert",
    "totalRhShareWarning": "Summe Anteil Raumwärme muss exakt 100% betragen!",
    "totalDhwShareWarning": "Summe Anteil Warmwasser muss exakt 100% betragen!",
    "total": "Total",
    "selectHeatGrid": "Auswahl Wärmenetz",
    "selectGridInfo": {
      "title": "Auswahl Wärmenetz",
      "text": "Wählen Sie ein selber definiertes Wärmenetz aus (siehe Einstellungen > Wärmenetze)."
    },
    "calibrationMethods": {
      "MANUAL": "Manuell",
      "INHERITED": "Vererbt",
      "MEASUREMENTS": "Messdaten",
      "NONE": "Keine Kalibrierung"
    },
    "highCalibrationFactorWarningTitle": "Effektiver Energieverbrauch höher als erwartet",
    "highCalibrationFactorWarning": "Der effektive Energieverbrauch liegt deutlich über dem modellierten Energiebedarf.<br /><br />Mögliche Ursachen:<li>Erfasste Sanierungen an der Gebäudehülle wurden nur teilweise oder nur oberflächlich und ohne Dämmung durchgeführt</li><li>Der Wärmeerzeuger liefert Wärme an weitere nicht erfasste Gebäude</li><li>Es befinden sich zusätzliche Nutzungen mit hohem Warmwasserverbrauch in der Liegenschaft (z.B. Gastronomie)</li><li>Falls mehrere Wärmeerzeuger vorhanden sind, entsprechen die angegebenen Anteile Raumwärme und Warmwasser nicht der Realität</li>",
    "lowCalibrationFactorWarningTitle": "Effektiver Energieverbrauch tiefer als erwartet",
    "lowCalibrationFactorWarning": "Der effektive Energieverbrauch liegt deutlich unter dem modellierten Energiebedarf.<br /><br />Mögliche Ursachen:<li>Es wurden zusätzliche Sanierungen an der Gebäudehülle durchgeführt aber nicht erfasst</li><li>Als der Kalibrierungswert erhoben wurde, gab es einen wesentlichen Leerstand</li><li>Es existieren zusätzliche Wärmeerzeuger, die nicht erfasst wurden (z.B. elektrische Warmwasserboiler)</li><li>Der Kalibrierungswert ist fehlerhaft oder unvollständig (z.B. nur eine Teilperiode)</li><li>Falls mehrere Wärmeerzeuger vorhanden sind, entsprechen die angegebenen Anteile Raumwärme und Warmwasser nicht der Realität</li>",
    "rhShareInfoBoxTitle": "Anteil Raumwärme",
    "rhShareInfoBox": "Anteil der Raumwärme, der durch diesen Wärmeerzeuger bereitgestellt wird.",
    "dhwShareInfoBoxTitle": "Anteil Warmwasser",
    "dhwShareInfoBox": "Anteil des Warmwassers, der durch diesen Wärmeerzeuger bereitgestellt wird.",
    "endEnergyInfoBoxTitle": "Modellierter Endenergiebedarf (ohne Kalibrierung)",
    "endEnergyInfoBox": "Berechneter Endenergiebedarf dieses Wärmeerzeugers (netto gelieferte Energie, z.B. Brennwert von Heizöl). Der Kalibrierungsfaktor wird hier nicht berücksichtigt.",
    "measuredInfoBoxTitle": "Gemessener Endenergiebedarf (witterungsbereinigt)",
    "measuredInfoBox": "Gemessener Endenergiebedarf dieses Wärmeerzeugers (netto gelieferte Energie, z.B. Brennwert von Heizöl).",
    "calibrationInfoBoxTitle": "Kalibrierung Endenergie",
    "calibrationInfoBox": "Effektiver Endenergieverbrauch dieses Wärmeerzeugers (netto gelieferte Energie, z.B. Brennwert von Heizöl). Mit diesem wird ein Kalibrierungsfaktor berechnet und damit die modellierte Nutzenergie (Raumwärme- und Warmwasserbedarf) sowohl des aktuellen Gebäudezustandes als auch des künftigen Verbrauchs korrigiert.",
    "altCalibrationInfoBox": "Normaler Energieverbrauch. Falls der gemessene Energieverbrauch vom erwarteten Energieverbrauch abweicht z.B. durch aussergewöhnliche Ereignisse, kann das hier angepasst werden um die Kalibration dennoch korrekt durchzuführen."
  }
}
</i18n>

<template>
  <div class="edit-heaters-table">
    <DetailList v-if="model.length > 0" has-header has-footer class="heating-table">
      <template #header>
        <span>
          {{ $t('heatingTypeTitle') }}
        </span>
        <span class="align-right">
          <span>{{ $t('rhShareTitle') }}</span>
          <InfoBox :title="$t('rhShareInfoBoxTitle')">{{ $t('rhShareInfoBox') }}</InfoBox>
        </span>
        <span class="align-right">
          <span>{{ $t('dhwShareTitle') }}</span>
          <InfoBox :title="$t('dhwShareInfoBoxTitle')">{{ $t('dhwShareInfoBox') }}</InfoBox>
        </span>
        <span class="align-right">
          <span>{{ $t('measuredTitle') }}</span>
          <InfoBox :title="$t('measuredInfoBoxTitle')">{{ $t('measuredInfoBox') }}</InfoBox>
        </span>
        <span class="align-right">
          <span>{{ $t('endEnergyTitle') }}</span>
          <InfoBox :title="$t('endEnergyInfoBoxTitle')">{{ $t('endEnergyInfoBox') }}</InfoBox>
        </span>
        <span class="align-right">
          <span>{{ $t('energyCalibration') }}</span>
          <template v-if="portfolio.enabled_features['ALT_CALIBRATION']">
            <InfoBox :title="$t('calibrationInfoBoxTitle')">{{ $t('altCalibrationInfoBox') }}</InfoBox>
          </template>
          <template v-else>
            <InfoBox :title="$t('calibrationInfoBoxTitle')">{{ $t('calibrationInfoBox') }}</InfoBox>
          </template>
        </span>
        <span class="align-right">
          <span>{{ $t('remarks') }}</span>
        </span>
        <span></span>
      </template>

      <li v-for="(h, index) in model" :key="index">
        <!-- Heating type -->
        <div>
          <HeatingTypePicker v-model="h.heating_type_id" :portfolio="portfolio" :edit="isEditing" @input="onInput" />
          <div v-if="h.heating_type_id === 'LOCAL'">
            <div v-if="gridOptions && gridOptions.length > 0">
              <div v-if="isEditing" class="select-grid-title">
                {{ $t('selectHeatGrid') }}
                <InfoBox :title="$t('selectGridInfo.title')">{{ $t('selectGridInfo.text') }}</InfoBox>
              </div>
              <HeatGridPicker
                v-model="h.heat_grid_identifier"
                :grid-options="gridOptions"
                :edit="isEditing"
                @input="onInput"
              />
            </div>
          </div>
        </div>

        <!-- Percentage room heating -->
        <div class="align-right">
          <NumericInput
            v-model="h.rh_share"
            :min="0"
            :max="100"
            :edit="isEditing"
            :units="'%'"
            @input="onInput"
            @validation="onValidation($event, `rhShare${index}`)"
          />
        </div>

        <!-- Percentage water heating -->
        <div class="align-right">
          <NumericInput
            v-model="h.dhw_share"
            :min="0"
            :max="100"
            :edit="isEditing"
            :units="'%'"
            @input="onInput"
            @validation="onValidation($event, `dhwShare${index}`)"
          />
        </div>

        <!-- Measured value -->
        <div class="align-right">
          <span v-if="getMeasuredEnergy(index)">
            {{ formatNumber(getMeasuredEnergy(index).value / 1000, 0) }} MWh
            <div class="source-info">{{ $t('yearLabel') }} {{ getMeasuredEnergy(index).year }}</div>
          </span>
          <span v-else>-</span>
        </div>

        <!-- Model value -->
        <div class="align-right">
          <span v-if="getEndEnergy(index)"> {{ formatNumber(getEndEnergy(index) / 1000, 0) }} MWh </span>
          <span v-else>-</span>
        </div>

        <!-- Calibration -->
        <div class="align-right">
          <NumericInput
            v-if="isEditing"
            v-model="h.energy_calibration"
            optional
            :min="0"
            :edit="isEditing"
            :units="'MWh'"
            @input="onInput"
            @validation="onValidation($event, `calibration${index}`)"
          />

          <div v-else>
            <template v-if="getCalibrationFactor(index)">
              <div class="calibration">
                <template v-if="getCalibrationFactor(index).method != 'NONE'">
                  <template v-if="getCalibrationFactor(index).value >= 1.0">
                    <DataWarning
                      v-if="getCalibrationFactor(index).value > 1.667"
                      :title="$t('highCalibrationFactorWarningTitle')"
                    >
                      <span v-html="$t('highCalibrationFactorWarning')" />
                    </DataWarning>
                    <span>+{{ formatNumber(getCalibrationFactor(index).value * 100 - 100, 1) }} %</span>
                  </template>
                  <template v-else>
                    <DataWarning
                      v-if="getCalibrationFactor(index).value < 0.6"
                      :title="$t('lowCalibrationFactorWarningTitle')"
                    >
                      <span v-html="$t('lowCalibrationFactorWarning')" />
                    </DataWarning>
                    <span>{{ formatNumber(getCalibrationFactor(index).value * 100 - 100, 1) }} %</span>
                  </template>
                </template>
                <div v-else>-</div>
              </div>

              <div class="source-info">
                {{ $t(`calibrationMethods.${getCalibrationFactor(index).method}`) }}
              </div>
            </template>
          </div>
        </div>

        <!-- Remarks -->
        <div>
          <TextInput v-if="isEditing" v-model="h.remarks" :edit="isEditing" :allow-empty="true" @input="onInput" />
          <div v-else-if="!isEditing && h.remarks" class="align-right">{{ h.remarks }}</div>
          <div v-else class="align-right">-</div>
        </div>

        <div>
          <ListButtonWrapper v-if="isEditing">
            <ListDeleteButton @click="onDeleteHeating(index)" />
          </ListButtonWrapper>
        </div>
      </li>

      <template #footer>
        <span>{{ $t('total') }}</span>
        <span class="align-right">{{ totalRhShare }} %</span>
        <span class="align-right">{{ totalDhwShare }} %</span>
        <span class="align-right">
          <template v-if="totalMeasuredEnergy"> {{ formatNumber(totalMeasuredEnergy / 1000, 0) }} MWh </template>
          <template v-else> - </template>
        </span>
        <span class="align-right">
          <template v-if="totalEndEnergy"> {{ formatNumber(totalEndEnergy / 1000, 0) }} MWh </template>
          <template v-else> - </template>
        </span>
        <span class="align-right">
          <template v-if="totalCalibration"> {{ formatNumber(totalCalibration, 0) }} MWh </template>
        </span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </template>
    </DetailList>

    <div v-else>
      {{ $t('noHeatingDefined') }}
    </div>
    <div v-if="model.length > 0 && totalRhShare !== 100" class="sum-warning">
      <p>{{ $t('totalRhShareWarning') }}</p>
    </div>
    <div v-if="model.length > 0 && totalDhwShare !== 100" class="sum-warning">
      <p>{{ $t('totalDhwShareWarning') }}</p>
    </div>
    <ButtonWrapper>
      <button v-if="isEditing" class="button" @click.prevent="onAddHeating">{{ $t('addHeatingButton') }}</button>
    </ButtonWrapper>
  </div>
</template>

<script>
import ComplexFormMixin from '@/components/shared/forms/ComplexFormMixin.vue'

import ListButtonWrapper from '@/components/shared/lists/ListButtonWrapper.vue'
import ListDeleteButton from '@/components/shared/lists/ListDeleteButton.vue'
import ButtonWrapper from '@/components/shared/ButtonWrapper.vue'
import HeatingTypePicker from '@/components/building/edit/HeatingTypePicker.vue'
import HeatGridPicker from '@/components/building/edit/HeatGridPicker.vue'
import DetailList from '@/components/shared/lists/DetailList.vue'
import NumericInput from '@/components/shared/forms/NumericInput.vue'
import DataWarning from '@/components/shared/DataWarning.vue'
import InfoBox from '@/components/shared/InfoBox.vue'
import TextInput from '@/components/shared/forms/TextInput.vue'

export default {
  mixins: [
    //
    ComplexFormMixin,
  ],

  components: {
    ListButtonWrapper,
    ListDeleteButton,
    ButtonWrapper,
    NumericInput,
    DetailList,
    InfoBox,
    DataWarning,
    HeatingTypePicker,
    HeatGridPicker,
    TextInput,
  },

  props: {
    heatersResults: {
      type: Array,
      required: false,
    },
    portfolio: {
      type: Object,
      required: true,
    },
  },

  computed: {
    // Overrides mixin function
    isValid() {
      return (
        this.noInvalidFields && (this.model.length === 0 || (this.totalRhShare === 100 && this.totalDhwShare === 100))
      )
    },

    totalRhShare() {
      return this.model.reduce((pv, cv) => pv + cv.rh_share, 0)
    },

    totalDhwShare() {
      return this.model.reduce((pv, cv) => pv + cv.dhw_share, 0)
    },

    totalEndEnergy() {
      if (this.heatersResults) {
        return this.heatersResults.reduce((pv, cv) => pv + cv.rh_end_energy + cv.dhw_end_energy, 0)
      }
      return undefined
    },

    totalMeasuredEnergy() {
      if (this.heatersResults) {
        return this.heatersResults.reduce(
          (pv, cv) => pv + (cv.measured_end_energy !== null ? cv.measured_end_energy.value : 0),
          0
        )
      }
      return undefined
    },

    totalCalibration() {
      return this.model.reduce((pv, cv) => pv + cv.energy_calibration, 0)
    },

    gridOptions() {
      return (
        this.portfolio &&
        this.portfolio.heat_grids.map((g) => {
          return {
            id: g.identifier,
            label: g.name,
          }
        })
      )
    },
  },

  methods: {
    getHeatingTypeRawName(heatingTypeId) {
      const heatingType = this.portfolio.heating_types.find((ht) => ht.id === heatingTypeId)
      if (heatingType) {
        return heatingType.name
      } else {
        return 'UNKNOWN'
      }
    },

    getCalibrationFactor(heaterIdx) {
      if (
        this.heatersResults &&
        this.heatersResults[heaterIdx] !== undefined &&
        this.heatersResults[heaterIdx].calibration_factor !== null
      ) {
        return this.heatersResults[heaterIdx].calibration_factor
      }
      return undefined
    },

    getEndEnergy(heaterIdx) {
      if (
        this.heatersResults &&
        this.heatersResults[heaterIdx] !== undefined &&
        this.heatersResults[heaterIdx].rh_end_energy !== null
      ) {
        return this.heatersResults[heaterIdx].rh_end_energy + this.heatersResults[heaterIdx].dhw_end_energy
      }
      return undefined
    },

    getMeasuredEnergy(heaterIdx) {
      if (
        this.heatersResults &&
        this.heatersResults[heaterIdx] !== undefined &&
        this.heatersResults[heaterIdx].measured_end_energy !== null
      ) {
        return this.heatersResults[heaterIdx].measured_end_energy
      }
      return undefined
    },

    onAddHeating() {
      const unknownHeatingType = this.portfolio.heating_types.find((ht) => ht.name === 'UNKNOWN')

      this.model = [
        ...this.model,
        {
          heating_type_id: unknownHeatingType ? 'UNKNOWN' : null,
          heat_grid_identifier: null,
          rh_share: this.model.length === 0 ? 100 : 0,
          dhw_share: this.model.length === 0 ? 100 : 0,
          energy_calibration: null,
          remarks: null,
        },
      ]
      this.onInput()
    },

    onDeleteHeating(index) {
      this.model.splice(index, 1)
      this.onInput()
    },
  },
}
</script>

<style lang="scss">
.edit-heaters-table {
  & .detail-list > ul > li {
    position: relative;
    display: grid;
    grid-template-columns: 1.5fr 1fr 1fr 1fr 1fr 1fr 1fr 50px;

    & > * {
      position: relative;
      width: 100%;
    }
  }

  & .sum-warning {
    color: var(--warning-color);
    padding: var(--box-padding);
  }

  & .align-right {
    text-align: right;
    justify-content: flex-end;
  }

  & .source-info {
    color: var(--secondary-text-color);
    font-size: var(--font-xs);
    line-height: 16px;
  }

  & .select-grid-title {
    margin-top: var(--spacing-s);
    margin-bottom: var(--spacing-xxs);
    color: var(--secondary-highlight-color);
    font-size: var(--font-xs);
    font-weight: 600;
  }

  .calibration {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: var(--spacing-xs);
  }
}
</style>
