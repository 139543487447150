<i18n>
{
  "de": {
    "elHeader": "Stromkonfiguration",
    "containerNote": "Das gewählte Stromprodukt wird für den Stromverbrauch (Allgemeinstrom und Mieterstrom) und für alle Heizungstypen mit dem Energieträger 'In der Liegenschaft definiertes Stromprodukt (Netz)' oder 'In der Liegenschaft definiertes Stromprodukt (Netz+PV)' verwendet."
  }
}
</i18n>

<template>
  <DetailListItem class="electricity-summary-row" :title="$t('elHeader')" expandable @expand="onModalOpen">
    <div class="electricity-summary-contents">
      <span v-if="electricitySummary">{{ electricitySummary }}</span>
    </div>

    <template #modal>
      <Modal v-if="isModalOpen" wide @close="onModalClose">
        <template #header>
          <h2>{{ $t('elHeader') }}</h2>
        </template>
        <p>{{ $t('containerNote') }}</p>
        <br />
        <EditElectricity :portfolio="portfolio" :building="building" />
      </Modal>
    </template>
  </DetailListItem>
</template>

<script>
import ModalMixin from '@/components/shared/ModalMixin.vue'

import Modal from '@/components/shared/Modal.vue'
import DetailListItem from '@/components/shared/lists/DetailListItem.vue'
import EditElectricity from '@/components/building/edit/EditElectricity.vue'

export default {
  mixins: [ModalMixin],

  components: {
    Modal,
    DetailListItem,
    EditElectricity,
  },

  props: {
    portfolio: {
      type: Object,
      required: true,
    },
    building: {
      type: Object,
      required: true,
    },
  },

  computed: {
    electricitySummary() {
      const electricitySummaryElements = []

      if (this.building.initial_state.electricity_grid_id) {
        electricitySummaryElements.push(`${this.getCarrierName(this.building.initial_state.electricity_grid_id)}`)
      }
      if (this.building.initial_state.electricity_pv_id) {
        electricitySummaryElements.push(`${this.getCarrierName(this.building.initial_state.electricity_pv_id)}`)
      }

      return electricitySummaryElements.join(', ') || '-'
    },
  },

  methods: {
    getCarrierName(id) {
      const carrier = this.portfolio.energy_carriers.find((carrier) => carrier.id === id)
      return carrier && carrier.default ? this.$t(`_energyCarriers.${carrier.name}`) : carrier.name
    },
  },
}
</script>
