<i18n>
{
  "de": {
    "header": "Leistungsbedarf Wärme"
  }
}
</i18n>

<template>
  <DetailListItem :title="$t('header')">
    <div class="two-columns">
      <span>{{ formatNumber(building.kpi.heating_power, 1) }} kW</span>
      <span>{{ formatNumber((building.kpi.heating_power * 1000) / building.kpi.energy_area, 1) }} W/m²</span>
    </div>
  </DetailListItem>
</template>

<script>
import DetailListItem from '@/components/shared/lists/DetailListItem.vue'

export default {
  components: {
    DetailListItem,
  },

  props: {
    building: {
      type: Object,
      required: true,
    },
  },
}
</script>
