<template>
  <div class="kpi-change-row">
    <div class="form-label">{{ label }}</div>
    <div v-if="before !== null">
      {{ formatNumber(before, 0) }} {{ units }}
      <EnergyLabel
        v-if="energyLabelBeforeRatio !== undefined"
        class="measure-energy-label"
        :value="energyLabelBeforeRatio"
      />
    </div>
    <div v-else>-</div>
    <div v-if="after !== null">
      {{ formatNumber(after, 0) }} {{ units }}
      <EnergyLabel
        v-if="energyLabelAfterRatio !== undefined"
        class="measure-energy-label"
        :value="energyLabelAfterRatio"
      />
    </div>
    <div v-else>-</div>
    <div v-if="before === 0">-</div>
    <div v-else-if="after === before">-</div>
    <div v-else-if="after > before">+{{ formatNumber(((after - before) / before) * 100, 0) }} %</div>
    <div v-else>{{ formatNumber(((after - before) / before) * 100, 0) }} %</div>
  </div>
</template>

<script>
import EnergyLabel from '@/components/shared/EnergyLabel.vue'

export default {
  components: {
    EnergyLabel,
  },

  props: {
    label: {
      type: String,
    },
    before: {
      type: Number,
      required: true,
    },
    after: {
      type: Number,
      required: true,
    },
    units: {
      type: String,
      required: true,
    },
    energyLabelBeforeRatio: {
      type: Number,
    },
    energyLabelAfterRatio: {
      type: Number,
    },
  },
}
</script>

<style>
.kpi-change-row {
  display: contents;
}
.kpi-change-row .measure-energy-label {
  display: inline-block;
  vertical-align: bottom;
  margin-left: var(--spacing-s);
}
</style>
