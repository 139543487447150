<template>
  <Box className="c-text-with-icon">
    <Tooltip v-if="tooltipText" :title="tooltipText">
      <Icon :icon="icon" className="icon" />
    </Tooltip>
    <Icon v-else :icon="icon" className="icon" />
    <Box>
      <span>{{ text }}</span>
      <slot />
    </Box>
  </Box>
</template>

<script>
import Box from '@/components/cui/layout/Box.vue'
import Tooltip from '@/components/cui/data-display/Tooltip.vue'
import Icon from '@/components/cui/data-display/Icon.vue'

export default {
  name: 'TextWithIcon',

  components: {
    Box,
    Tooltip,
    Icon,
  },

  props: {
    icon: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      default: '',
    },
    tooltipText: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss" scoped>
.c-text-with-icon {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);

  & .icon {
    width: 20px;
    opacity: 0.4;
  }
}
</style>
