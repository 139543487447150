<template>
  <DetailListItem class="tag-group-summary-row" :title="tagGroup.name" expandable @expand="onModalOpen">
    <span v-if="selectedTag">{{ selectedTag.name }}</span>
    <span v-else>-</span>
    <template #modal>
      <Modal v-if="isModalOpen" @close="onModalClose">
        <template #header>
          <h2>{{ tagGroup.name }}</h2>
        </template>
        <EditTagGroup :building="building" :portfolio="portfolio" :tag-group="tagGroup" />
      </Modal>
    </template>
  </DetailListItem>
</template>

<script>
import ModalMixin from '@/components/shared/ModalMixin.vue'

import Modal from '@/components/shared/Modal.vue'
import EditTagGroup from '@/components/building/edit/EditTagGroup.vue'
import DetailListItem from '@/components/shared/lists/DetailListItem.vue'

export default {
  mixins: [
    //
    ModalMixin,
  ],

  components: {
    Modal,
    DetailListItem,
    EditTagGroup,
  },

  props: {
    portfolio: {
      type: Object,
      required: true,
    },
    building: {
      type: Object,
      required: true,
    },
    tagGroup: {
      type: Object,
      required: true,
    },
  },

  computed: {
    selectedTag() {
      return this.building.tags.find((t) => t.gname === this.tagGroup.name)
    },
  },
}
</script>
