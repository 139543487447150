<template>
  <div :class="`cui-card ${className}`">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'Card',

  props: {
    className: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss" scoped>
.cui-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  border: var(--box-border);
  border-radius: var(--box-radius);
}
</style>
