<i18n>
{
  "de": {
    "header": "Strombedarf"
  }
}
</i18n>

<template>
  <DetailListItem :title="$t('header')" expandable @expand="onModalOpen">
    <div class="two-columns">
      <span>{{ formatNumber(building.kpi.el_demand / 1000, 1) }} MWh</span>
      <span>{{ formatNumber(building.kpi.el_demand / building.kpi.energy_area, 1) }} kWh/m²</span>
    </div>
    <template #rightContent>
      <HistogramChart
        :key="'el_histogram'"
        :group-by-key="'el_demand'"
        area-specific
        :highlight-value="building.kpi['el_demand'] / building.kpi.energy_area"
        :chart-data="chartData"
        mini-chart
        class="histogram-chart"
      />
    </template>
    <template #modal>
      <ElectricityDemandModal v-if="modalOpen" :portfolio="portfolio" :building="building" :chart-data="chartData" @close="onModalClose" />
    </template>
  </DetailListItem>
</template>

<script>
import DetailListItem from '@/components/shared/lists/DetailListItem.vue'
import ElectricityDemandModal from '@/components/building/kpi/ElectricityDemandModal.vue'
import HistogramChart from '@/components/portfolio/kpi/charts/HistogramChart.vue'

export default {
  components: {
    DetailListItem,
    HistogramChart,
    ElectricityDemandModal,
  },

  props: {
    portfolio: {
      type: Object,
    },
    building: {
      type: Object,
      required: true,
    },
    chartData: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      modalOpen: false,
    }
  },

  methods: {
    onModalOpen() {
      this.modalOpen = true
    },

    onModalClose() {
      this.modalOpen = false
    },
  },
}
</script>
