<i18n>
  {
    "de": {
      "roof": "Dach",
      "fassade": "Fassade",
      "editRoofLayer": "Dach bearbeiten",
      "viewRoofLayer": "Dach ansehen"
    }
  }
  </i18n>

<template>
  <div class="c-building-map">
    <MglMap
      :accessToken="accessToken"
      :mapStyle.sync="mapStyle"
      :scrollZoom="false"
      :center="coordinates"
      :zoom="initialZoom"
      :attributionControl="false"
      class="map-container"
      @load="onMapLoaded"
    >
      <!-- Navigation control -->
      <MglNavigationControl position="top-left" />

      <!-- GeoJSON layers -->
      <EnergyGridPolygonLayer
        v-if="mapLoaded && building.energy_grids"
        :building="building"
        sourceId="energyGridPolygonSource"
        layerId="energyGridPolygonLayer"
        @selectfeature="gridSelect"
      />
      <EnergyGridPointLayer
        v-if="mapLoaded && building.energy_grids"
        :building="building"
        sourceId="energyGridPointSource"
        layerId="energyGridPointLayer"
        @selectfeature="gridSelect"
      />
      <ParcelLayer
        v-if="mapLoaded"
        :building="building"
        sourceId="parcelsSource"
        layerId="parcelsLayer"
        @selectfeature="parcelSelect"
      />
      <RoofTileLayer
        v-if="mapLoaded"
        :building="!isEditLayers ? building : null"
        sourceId="roofTilesSource"
        layerId="roofTilesLayer"
        @selectfeature="roofTileSelect"
      />
      <RoofEditTileLayer
        v-if="mapLoaded && isEditRoofTilesLayer"
        :building="building"
        sourceId="roofEditTilesSource"
        layerId="roofEditTilesLayer"
        @selectfeature="roofTileSelect"
      />
      <!-- <RoofEditTileIconsLayer
        v-if="mapLoaded && isEditRoofTilesLayer"
        :building="building"
        sourceId="roofEditTilesIconSource"
        layerId="roofEditTilesIconLayer"
        @selectfeature="roofTileSelect"
      /> -->
      <FassadeTileLayer
        v-if="mapLoaded"
        :building="!isEditLayers ? building : null"
        sourceId="fassadeTilesSource"
        layerId="fassadeTilesLayer"
        @selectfeature="fassadeTileSelect"
      />
      <!-- <FassadeEditTileLayer
        v-if="mapLoaded && isEditFassadeTilesLayer"
        :building="building"
        sourceId="fassadeEditTilesSource"
        layerId="fassadeEditTilesLayer"
        @selectfeature="fassadeTileSelect"
      /> -->
      <EgidLayer
        v-if="mapLoaded"
        :building="building"
        sourceId="egidSource"
        layerId="egidLayer"
        @selectfeature="egidSelect"
      />

      <!-- Popups -->
      <RoofTilePopup
        :building="building"
        :feature="activeFeature"
        :coordinates="popupCoordinates"
        :showed="activeLayer === 'roofTiles'"
        @close="onPopupClose"
      />
      <FassadeTilePopup
        :building="building"
        :feature="activeFeature"
        :coordinates="popupCoordinates"
        :showed="activeLayer === 'fassadeTiles'"
        @close="onPopupClose"
      />
      <ParcelPopup
        :feature="activeFeature"
        :coordinates="popupCoordinates"
        :showed="activeLayer === 'parcels'"
        @close="onPopupClose"
      />
      <EgidPopup
        :feature="activeFeature"
        :coordinates="popupCoordinates"
        :showed="activeLayer === 'egid'"
        @close="onPopupClose"
      />
      <EnergyGridPopup
        v-if="building.energy_grids"
        :feature="activeFeature"
        :coordinates="popupCoordinates"
        :showed="activeLayer === 'energyGrids'"
        @close="onPopupClose"
      />
    </MglMap>

    <div v-if="mapLoaded" class="edit-layers">
      <template v-if="!isEditLayers">
        <button type="button" class="edit-layers-button" @click="onEditRoofTilesLayer">
          <template v-if="getBuildingPermission('EDIT_FEASIBILITY')">
            <img src="/icons/edit-2.svg" />
            <span>
              {{ $t('editRoofLayer') }}
            </span>
          </template>
          <template v-else>
            <span>
              {{ $t('viewRoofLayer') }}
            </span>
          </template>
        </button>
      </template>

      <template v-if="isEditLayers">
        <button type="button" class="type-button" @click="onEditRoofTilesLayer">
          <strong>
            {{ $t('roof') }}
          </strong>
          <img v-if="getBuildingPermission('EDIT_FEASIBILITY') && isEditRoofTilesLayer" src="/icons/edit-2.svg" />
        </button>
        <!-- <button type="button" class="type-button" @click="onEditFassadeTilesLayer">
          <strong>
            {{ $t('fassade') }}
          </strong>
          <img v-if="isEdifFassadeTilesLayer" src="/icons/edit-2.svg" />
        </button> -->
        <button type="button" class="close-button" @click="onCloseEditLayers"><img src="/icons/x.svg" /></button>
      </template>
    </div>

    <div class="gis-links">
      <a v-if="googleMapsUrl" :href="googleMapsUrl" target="_blank">Google Maps</a>
      <a v-if="swissGisUrl" :href="swissGisUrl" target="_blank">map.geo.admin.ch</a>
      <a v-if="cantonZhGisAvUrl" :href="cantonZhGisAvUrl" target="_blank">Amtliche Vermessung Kanton ZH</a>
      <a v-if="oerebZhGisUrl" :href="oerebZhGisUrl" target="_blank">ÖREB Kanton ZH</a>
    </div>
  </div>
</template>

<script>
import { MglMap, MglNavigationControl } from 'vue-mapbox'
import { mapGetters } from 'vuex'

import FassadeTileLayer from '@/components/building/map/FassadeTileLayer.vue'
import FassadeTilePopup from '@/components/building/map/FassadeTilePopup.vue'
import RoofTileLayer from '@/components/building/map/RoofTileLayer.vue'
import RoofEditTileLayer from '@/components/building/map/RoofEditTileLayer.vue'
import RoofTilePopup from '@/components/building/map/RoofTilePopup.vue'
import ParcelLayer from '@/components/building/map/ParcelLayer.vue'
import ParcelPopup from '@/components/building/map/ParcelPopup.vue'
import EgidLayer from '@/components/building/map/EgidLayer.vue'
import EgidPopup from '@/components/building/map/EgidPopup.vue'
import EnergyGridPolygonLayer from '@/components/building/map/EnergyGridPolygonLayer.vue'
import EnergyGridPointLayer from '@/components/building/map/EnergyGridPointLayer.vue'
import EnergyGridPopup from '@/components/building/map/EnergyGridPopup.vue'

export default {
  components: {
    MglMap,
    MglNavigationControl,
    FassadeTileLayer,
    FassadeTilePopup,
    RoofTileLayer,
    RoofEditTileLayer,
    RoofTilePopup,
    ParcelLayer,
    ParcelPopup,
    EgidLayer,
    EgidPopup,
    EnergyGridPolygonLayer,
    EnergyGridPointLayer,
    EnergyGridPopup,
  },

  props: {
    building: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      mapLoaded: false,
      accessToken: 'pk.eyJ1IjoibWl2dW5lIiwiYSI6ImNsNWNreTd0cTBpZHkza28xeWo1ODJzamEifQ.Srz2_XsFlucH_7qZzPqkLQ',
      mapStyle: 'https://vectortiles.geo.admin.ch/styles/ch.swisstopo.leichte-basiskarte.vt/style.json',
      activeLayer: undefined,
      activeFeature: undefined,
      popupCoordinates: [8, 48], // this can't be blank!  it won't be shown but pick something
      isEditRoofTilesLayer: false,
      isEditFassadeTilesLayer: false,
    }
  },

  computed: {
    ...mapGetters({
      getBuildingPermission: 'permissions/getBuildingPermission',
    }),

    coordinates() {
      return [(this.building.bbox[0] + this.building.bbox[2]) / 2, (this.building.bbox[1] + this.building.bbox[3]) / 2]
    },

    initialZoom() {
      // https://wiki.openstreetmap.org/wiki/Zoom_levels
      return Math.min(18, Math.floor(Math.log2(360 / (this.building.bbox[2] - this.building.bbox[0]))))
    },

    googleMapsUrl() {
      const zoom = 125
      const tilt = 45
      const angle = 0
      const cameraLatitude = this.building.latitude - 0.001 // move camera south
      const cameraLongitude = this.building.longitude
      return `https://www.google.com/maps/place/${this.building.latitude} N ${this.building.longitude} E/@${cameraLatitude},${cameraLongitude},${zoom}a,35y,${angle}h,${tilt}t/data=!3m1!1e3!4m5!3m4!1s0x0:0x0!8m2!3d${this.building.latitude}!4d${this.building.longitude}`
    },

    swissGisUrl() {
      if (this.building.location_info.state !== 'OK') {
        return false
      }
      const zoom = 12
      const coordsEast = this.building.location_info.data.coordinates_lv95.x
      const coordsNorth = this.building.location_info.data.coordinates_lv95.y
      return `https://map.geo.admin.ch/?lang=de&topic=ech&bgLayer=ch.swisstopo.pixelkarte-farbe&layers=ch.swisstopo.zeitreihen,ch.swisstopo-vd.amtliche-vermessung,ch.kantone.cadastralwebmap-farbe,ch.swisstopo.swissimage-product,ch.bfe.solarenergie-eignung-fassaden,ch.bfe.solarenergie-eignung-daecher,ch.bfs.gebaeude_wohnungs_register&layers_visibility=false,false,true,false,false,false,true&layers_timestamp=18641231,,,,,&E=${coordsEast}&N=${coordsNorth}&zoom=${zoom}`
    },

    cantonZhGisAvUrl() {
      if (
        this.building.location_info.state !== 'OK' ||
        this.building.gwr.entries === null ||
        this.building.gwr.entries.length === 0 ||
        this.building.gwr.entries[0].properties.gdekt !== 'ZH'
      ) {
        return false
      }
      const coordsEast = this.building.location_info.data.coordinates_lv95.x
      const coordsNorth = this.building.location_info.data.coordinates_lv95.y
      const scale = 488
      return `https://maps.zh.ch?topic=AVfarbigZH&scale=${scale}&x=${coordsEast}&y=${coordsNorth}&srid=2056&offlayers=av-stand%2CLCOBJPROJ%2Cbezirkslabels`
    },

    oerebZhGisUrl() {
      if (
        this.building.location_info.state !== 'OK' ||
        this.building.gwr.entries === null ||
        this.building.gwr.entries.length === 0 ||
        this.building.gwr.entries[0].properties.gdekt !== 'ZH'
      ) {
        return false
      }
      const coordsEast = this.building.location_info.data.coordinates_lv95.x
      const coordsNorth = this.building.location_info.data.coordinates_lv95.y
      const scale = 488
      return `https://maps.zh.ch?topic=OerebKatasterZH&scale=${scale}&x=${coordsEast}&y=${coordsNorth}&srid=2056&offlayers=projektierungszonen-flughafenanlagen%2Csicherheitszonen%2Ckbs-oev%2Ckbs-zfp%2Ckbs-militaer-bund%2Cul-breiche%2Claermempfindlichkeitsstufen%2Cls-proj%2Cul-b-proj%2Ckbs%2Cgrundwasserschutzareale%2Cgwsa-proj%2Cgrundwasserschutzzonen%2Cgwsz-proj%2Cgewaesserabstandslinien%2Cgal-proj%2CBaulinien_Nationalstrassen_OeREB%2Cbaulinien%2Cbl-proj%2Cwaldgrenzen%2Cwg-proj%2Cwaldabstandslinien%2Cwal-proj%2Cmarkierung-liegenschaft%2Cmarkierung-sr`
    },

    isEditLayers() {
      return this.isEditRoofTilesLayer || this.isEditFassadeTilesLayer
    },
  },

  created() {
    this.map = null
  },

  methods: {
    onMapLoaded(event) {
      this.map = event.map
      /*
      example to load custom image (don't remove)
      this.map.loadImage('https://docs.mapbox.com/mapbox-gl-js/assets/cat.png', (error, image) => {
        if (error) {
          throw error
        }
        this.map.addImage('cat', image)
        this.mapLoaded = true
      }) */
      this.mapLoaded = true
    },

    onPopupClose(event) {
      this.activeLayer = undefined
      this.activeFeature = undefined
    },

    egidSelect(data) {
      this.activeLayer = 'egid'
      this.activeFeature = data.feature
      this.popupCoordinates = data.coordinates
    },

    parcelSelect(data) {
      this.activeLayer = 'parcels'
      this.activeFeature = data.feature
      this.popupCoordinates = data.coordinates
    },

    roofTileSelect(data) {
      this.activeLayer = 'roofTiles'
      this.activeFeature = data.feature
      this.popupCoordinates = data.coordinates
    },

    fassadeTileSelect(data) {
      this.activeLayer = 'fassadeTiles'
      this.activeFeature = data.feature
      this.popupCoordinates = data.coordinates
    },

    gridSelect(data) {
      this.activeLayer = 'energyGrids'
      this.activeFeature = data.feature
      this.popupCoordinates = data.coordinates
    },

    onEditRoofTilesLayer() {
      this.isEditRoofTilesLayer = true
      this.isEditFassadeTilesLayer = false
    },

    onEditFassadeTilesLayer() {
      this.isEditRoofTilesLayer = false
      this.isEditFassadeTilesLayer = true
    },

    onCloseEditLayers() {
      this.isEditRoofTilesLayer = false
      this.isEditFassadeTilesLayer = false
    },
  },
}
</script>

<style lang="scss">
.c-building-map {
  overflow: hidden;
  position: relative;
  flex: auto;

  & .info-panel {
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 300px;
  }

  & .map-container {
    height: 420px !important;
  }

  & .gis-links {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: var(--spacing-xs);
    gap: var(--spacing-xs);

    & a {
      background-color: white;
      padding: var(--spacing-xs);
      font-size: var(--font-xs);
      border: var(--box-border);
      border-radius: var(--box-radius);
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}

.edit-layers {
  position: absolute;
  top: 10px;
  left: 50px;
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px;
  background-color: white;
  border: var(--box-border);
  border-radius: var(--box-radius);
  min-width: 160px;
  min-height: 40px;

  & .edit-layers-button {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 6px 8px;
    background-color: white;
    border: none;
    gap: var(--spacing-s);
  }

  & .edit-layers-button img {
    width: 16px;
    height: auto;
  }

  & .type-button {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 6px 16px;
    background-color: #f5f5f5;
    text-decoration: underline;
    border: none;
    border-radius: 2px;
    gap: var(--spacing-s);

    & img {
      width: 16px;
      height: auto;
    }
  }

  & .close-button {
    flex: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px;
    margin: 0 0 0 var(--spacing-xs);
    background-color: white;
    border: none;

    & img {
      width: 20px;
      height: auto;
    }
  }

  & button:hover {
    cursor: pointer;
  }
}
</style>
