<i18n>
{
  "de": {
    "header": "Nutzenergie Raumwärme",
    "highCalibrationFactorWarningTitle": "Effektiver Energieverbrauch höher als erwartet",
    "highCalibrationFactorWarning": "Der effektive Energieverbrauch liegt deutlich über dem modellierten Energiebedarf. Für mögliche Ursachen siehe Kalibrierung Wärmeerzeugung.",
    "lowCalibrationFactorWarningTitle": "Effektiver Energieverbrauch tiefer als erwartet",
    "lowCalibrationFactorWarning": "Der effektive Energieverbrauch liegt deutlich unter dem modellierten Energiebedarf. Für mögliche Ursachen siehe Kalibrierung Wärmeerzeugung."
  }
}
</i18n>

<template>
  <DetailListItem class="rh-summary-row" :title="$t('header')" expandable @expand="onModalOpen">
    <div class="two-columns">
      <span class="value">
        <span>{{ formatNumber(building.kpi.rh_demand / 1000, 0) }} MWh</span>
        <template v-if="building.kpi.rh_calibration > 1.667">
          <DataWarning :title="$t('highCalibrationFactorWarningTitle')">
            <span v-html="$t('highCalibrationFactorWarning')" />
          </DataWarning>
        </template>
        <template v-else-if="building.kpi.rh_calibration < 0.6">
          <DataWarning :title="$t('lowCalibrationFactorWarningTitle')">
            <span v-html="$t('lowCalibrationFactorWarning')" />
          </DataWarning>
        </template>
      </span>
      <span>
        <span>{{ formatNumber(building.kpi.rh_demand / building.kpi.energy_area, 0) }} kWh/m²</span>
        &nbsp;<EnergyLabel :value="rhRatio" class="energy-label" />
      </span>
    </div>
    <template #rightContent>
      <HistogramChart
        :key="'rh_histogram'"
        :group-by-key="'rh_demand'"
        area-specific
        :highlight-value="building.kpi['rh_demand'] / building.kpi.energy_area"
        :chart-data="chartData"
        mini-chart
        class="histogram-chart"
      />
    </template>
    <template #modal>
      <RhModal v-if="modalOpen" :building="building" :portfolio="portfolio" :chart-data="chartData" @close="onModalClose" />
    </template>
  </DetailListItem>
</template>

<script>
import EnergyLabel from '@/components/shared/EnergyLabel.vue'
import DetailListItem from '@/components/shared/lists/DetailListItem.vue'
import DataWarning from '@/components/shared/DataWarning.vue'
import RhModal from '@/components/building/kpi/RhModal.vue'
import HistogramChart from '@/components/portfolio/kpi/charts/HistogramChart.vue'

export default {
  components: {
    DetailListItem,
    EnergyLabel,
    DataWarning,
    RhModal,
    HistogramChart,
  },

  props: {
    portfolio: {
      type: Object,
      required: true,
    },
    building: {
      type: Object,
      required: true,
    },
    chartData: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      modalOpen: false,
    }
  },

  computed: {
    rhRatio() {
      return this.building.kpi.rh_demand / this.building.kpi.energy_area / this.building.kpi.rh_limit
    },
  },

  methods: {
    onModalOpen() {
      this.modalOpen = true
    },

    onModalClose() {
      this.modalOpen = false
    },
  },
}
</script>

<style lang="scss" scoped>
.value {
  display: flex;
  flex-direction: row;
  align-items: center;

  & > * + * {
    margin-left: var(--spacing-xs);
  }
}
</style>
