<i18n>
{
  "de": {
    "modalTitle": "Wärmebedarf Warmwasser",
    "dhwCalibrationLabel": "Kalibrierungsfaktor",
    "dhwTotalLabel": "Nutzenergie",
    "dhwEndEnergyLabel": "Endenergie",
    "calibrationInfoBox": "Abweichung des effektiven Warmwasserbedarfs vom modellierten Warmwasserbedarf. Dieser Wert wird aus der Kalibrierung des Endenergiebedarfs der Wärmeerzeuger ermittelt",
    "calibrationInfoBoxTitle": "Kalibrierungsfaktor Nutzenergie Warmwasser",
    "dhwInfoBoxTitle": "Nutzenergie Warmwasser",
    "dhwInfoBox": "Nutzenergiebedarf Warmwasser unter Berücksichtigung des Kalibrierungsfaktors.",
    "dhwEndEnergyInfoBoxTitle": "Endenergie Warmwasser",
    "dhwEndEnergyInfoBox": "Endenergiebedarf Warmwasser (netto gelieferte Energie, z.B. Brennwert von Heizöl) unter Berücksichtigung des Kalibrierungsfaktors. Die Differenz zur Nutzenergie entsteht durch die Nutzungsziffer des Wärmeerzeugers.",
    "highCalibrationFactorWarningTitle": "Effektiver Energieverbrauch höher als erwartet",
    "highCalibrationFactorWarning": "Der effektive Energieverbrauch liegt deutlich über dem modellierten Energiebedarf. Für mögliche Ursachen siehe Kalibrierung Wärmeerzeugung.",
    "lowCalibrationFactorWarningTitle": "Effektiver Energieverbrauch tiefer als erwartet",
    "lowCalibrationFactorWarning": "Der effektive Energieverbrauch liegt deutlich unter dem modellierten Energiebedarf. Für mögliche Ursachen siehe Kalibrierung Wärmeerzeugung."
  }
}
</i18n>

<template>
  <Modal class="dhw-demand-modal" @close="$emit('close', $event)">
    <template #header>
      <h2>{{ $t('modalTitle') }}</h2>
      <h3>{{ building.identifier }} / {{ building.description }}</h3>
    </template>
    <DetailList>
      <DetailListItem :title="$t('dhwTotalLabel')" :info-title="$t('dhwInfoBoxTitle')" :info-text="$t('dhwInfoBox')">
        <div class="two-columns">
          <span>{{ formatNumber(building.kpi.dhw_demand / 1000, 1) }} MWh</span>
          <span>{{ formatNumber(building.kpi.dhw_demand / building.kpi.energy_area, 1) }} kWh/m²</span>
        </div>
      </DetailListItem>
      <DetailListItem
        :title="$t('dhwEndEnergyLabel')"
        :info-title="$t('dhwEndEnergyInfoBoxTitle')"
        :info-text="$t('dhwEndEnergyInfoBox')"
      >
        <div class="two-columns">
          <span>{{ formatNumber(building.kpi.dhw_end_energy / 1000, 1) }} MWh</span>
          <span>{{ formatNumber(building.kpi.dhw_end_energy / building.kpi.energy_area, 0) }} kWh/m²</span>
        </div>
      </DetailListItem>
      <DetailListItem
        v-if="building.kpi.dhw_calibration !== 1.0"
        :title="$t('dhwCalibrationLabel')"
        :info-title="$t('calibrationInfoBoxTitle')"
        :info-text="$t('calibrationInfoBox')"
      >
        <div v-if="building.kpi.dhw_calibration >= 1.0" class="value">
          <span>+{{ formatNumber(building.kpi.dhw_calibration * 100 - 100, 1) }} %</span>
          <DataWarning v-if="building.kpi.dhw_calibration > 1.667" :title="$t('highCalibrationFactorWarningTitle')">
            <span v-html="$t('highCalibrationFactorWarning')" />
          </DataWarning>
        </div>
        <div v-else class="value">
          <span>{{ formatNumber(building.kpi.dhw_calibration * 100 - 100, 1) }} %</span>
          <DataWarning v-if="building.kpi.dhw_calibration < 0.6" :title="$t('lowCalibrationFactorWarningTitle')">
            <span v-html="$t('lowCalibrationFactorWarning')" />
          </DataWarning>
        </div>
      </DetailListItem>
    </DetailList>
  </Modal>
</template>

<script>
import Modal from '@/components/shared/Modal.vue'
import DetailList from '@/components/shared/lists/DetailList.vue'
import DetailListItem from '@/components/shared/lists/DetailListItem.vue'
import DataWarning from '@/components/shared/DataWarning.vue'

export default {
  components: {
    Modal,
    DataWarning,
    DetailList,
    DetailListItem,
  },

  props: {
    building: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.dhw-demand-modal {
  & .value {
    display: flex;
    flex-direction: row;
    align-items: center;

    & > * + * {
      margin-left: var(--spacing-xs);
    }
  }
}
</style>
