<i18n>
{
  "de": {
    "header": {
      "s12e": "Scope 1-2 Emissionen",
      "s123e": "Scope 1-3 Emissionen",
      "per": "Primärenergie (erneuerbar)"
    },
    "units": {
      "s12e": "t",
      "s123e": "t",
      "per": "MWh"
    },
    "units_specific": {
      "s12e": "kg CO₂e/m²",
      "s123e": "kg CO₂e/m²",
      "per": "kWh/m²"
    }
  }
}
</i18n>

<template>
  <DetailListItem
    :title="getIndicatorName"
    :expandable="hasNonZeroTotalValue"
    class="kpi-summary-row"
    @expand="onModalOpen()"
  >
    <div class="two-columns">
      <!-- Total value -->
      <template>
        <span> {{ formatNumber(totalValue / 1000, 0) }} {{ bigUnit }} </span>
      </template>
      <!-- Value per square meter -->
      <template>
        <span>
          {{ formatNumber(totalValue / building.kpi.energy_area, 0) }} {{ specificUnit }}
          <EnergyLabel v-if="kpiType === 'pe'" :value="peRatio" class="energy-label" />
        </span>
      </template>
    </div>

    <template #rightContent>
      <HistogramChart
        :key="`${kpiType}_histogram`"
        :group-by-key="`${kpiType}`"
        area-specific
        :highlight-value="building.kpi[kpiType] / building.kpi.energy_area"
        :chart-data="chartData"
        mini-chart
        class="histogram-chart histogram-chart-scoped"
      />
    </template>

    <template v-if="hasNonZeroTotalValue" #modal>
      <KpiModal
        v-if="modalOpen"
        :kpi-type="kpiType"
        :building="building"
        :portfolio="portfolio"
        :chart-data="chartData"
        @close="onModalClose()"
      />
    </template>
  </DetailListItem>
</template>

<script>
import { mapGetters } from 'vuex'

import EnergyLabel from '@/components/shared/EnergyLabel.vue'
import DetailListItem from '@/components/shared/lists/DetailListItem.vue'
import KpiModal from '@/components/building/kpi/KpiModal.vue'
import HistogramChart from '@/components/portfolio/kpi/charts/HistogramChart.vue'

export default {
  components: {
    DetailListItem,
    EnergyLabel,
    KpiModal,
    HistogramChart,
  },

  props: {
    portfolio: {
      type: Object,
    },
    building: {
      type: Object,
      required: true,
    },
    kpiType: {
      type: String, // s1e, s2e, s3e, s12e, s123e, pe, penr, per, ee
      required: true,
    },
    chartData: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      modalOpen: false,
    }
  },

  computed: {
    ...mapGetters({
      __getEnhancedSustainabilityIndicator: 'portfolio/getEnhancedSustainabilityIndicatorByIdentifier',
    }),

    sustainabilityIndicator() {
      return this.__getEnhancedSustainabilityIndicator(this.kpiType)
    },

    getIndicatorName() {
      if (this.sustainabilityIndicator === undefined) {
        return this.kpiType
      }
      if (this.sustainabilityIndicator === null) {
        return this.$t(`header.${this.kpiType}`)
      }
      return this.sustainabilityIndicator.name
    },

    specificUnit() {
      if (this.sustainabilityIndicator === undefined) {
        return this.kpiType
      }
      if (this.sustainabilityIndicator === null) {
        return this.$t(`units_specific.${this.kpiType}`)
      }
      return this.sustainabilityIndicator.getSpecificTargetUnit()
    },

    bigUnit() {
      if (this.sustainabilityIndicator === undefined) {
        return this.kpiType
      }
      if (this.sustainabilityIndicator === null) {
        return this.$t(`units.${this.kpiType}`)
      }
      return this.sustainabilityIndicator.getBigTargetUnit()
    },

    peRatio() {
      return this.building.kpi.pe / this.building.kpi.energy_area / this.building.kpi.pe_ref
    },

    totalValue() {
      if (this.building.kpi[this.kpiType]) {
        return this.building.kpi[this.kpiType]
      }
      return 0
    },

    hasNonZeroTotalValue() {
      return this.totalValue !== 0
    },
  },

  methods: {
    onModalOpen() {
      this.modalOpen = true
    },

    onModalClose() {
      this.modalOpen = false
    },
  },
}
</script>

<style scoped>
.histogram-chart-scoped {
  margin-right: 7px;
}
</style>
