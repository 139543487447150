<template>
  <Grid class="c-overview-section" item-width="440px" spacing="var(--spacing-m)">
    <!-- Group: Status and location -->
    <StatusAndLocationOverview :building="building" />
    <!-- Group: Building -->
    <BuildingDetailsOverview :building="building" :portfolio="portfolio" />
    <!-- Group: Feasibility and potential analyses -->
    <FeasibilityAndPotentialOverview
      v-if="portfolio && portfolio.enabled_features.HEATING_FEASIBILITY"
      :building="building"
    />
    <!-- Group: Categories -->
    <TagGroupOverview :portfolio="portfolio" :building="building" :manual-tags="manualTags" />
    <!-- Group: Energy -->
    <EnergyKpiOverview
      v-if="building && building.kpi && portfolio && portfolio.enabled_features.KPI"
      :portfolio="portfolio"
      :building="building"
      :chart-data="chartData"
    />
    <!-- Group: Emissions -->
    <EmissionsKpiOverview
      v-if="building && building.kpi && portfolio && portfolio.enabled_features.KPI"
      :portfolio="portfolio"
      :building="building"
      :chart-data="chartData"
    />
  </Grid>
</template>

<script>
import Grid from '@/components/cui/layout/Grid.vue'

import StatusAndLocationOverview from '@/components/building/overview/StatusAndLocationOverview.vue'
import FeasibilityAndPotentialOverview from '@/components/building/overview/FeasibilityAndPotentialOverview.vue'
import BuildingDetailsOverview from '@/components/building/overview/BuildingDetailsOverview.vue'
import TagGroupOverview from '@/components/building/overview/TagGroupOverview.vue'
import EmissionsKpiOverview from '@/components/building/kpi/EmissionsKpiOverview.vue'
import EnergyKpiOverview from '@/components/building/kpi/EnergyKpiOverview.vue'

export default {
  components: {
    Grid,
    TagGroupOverview,
    StatusAndLocationOverview,
    FeasibilityAndPotentialOverview,
    BuildingDetailsOverview,
    EmissionsKpiOverview,
    EnergyKpiOverview,
  },

  props: {
    building: {
      type: Object,
      required: true,
    },
    portfolio: {
      type: Object,
      required: true,
    },
    chartData: {
      type: Array,
      required: true,
    },
  },

  computed: {
    manualTags() {
      return this.portfolio.tag_groups.filter((t) => !t.auto)
    },
  },
}
</script>
