<i18n>
{
  "de": {
    "modalTitle": "Strombedarf",
    "demandTotalLabel": "Modellierter Strombedarf",
    "measuredDemandTotalLabel": "Gemessener Strombedarf (Mittelwert)",
    "demandByUsage": "Nach Verwendungszweck",
    "currentTitle": "Aktuelle Kennzahlen",
    "measuredTitle": "Messwerte",
    "histogramTitle": "Im Vergleich mit anderen Liegenschaften",
    "projectionTitle": "Absenkpfad"
  }
}
</i18n>

<template>
  <Modal class="electricity-demand-modal" wide @close="$emit('close', $event)">
    <template #header>
      <h2>{{ $t('modalTitle') }}</h2>
      <h3>{{ building.identifier }} / {{ building.description }}</h3>
    </template>
    <Tabs group="electricity-demand-modal-tabs">
      <Tab :title="$t('currentTitle')">
        <div class="current-kpis">
          <DetailList>
            <DetailListItem :title="$t('demandTotalLabel')">
              <div class="two-columns">
                <span>{{ formatNumber(building.kpi.el_demand / 1000, 1) }} MWh</span>
                <span>{{ formatNumber(building.kpi.el_demand / building.kpi.energy_area, 1) }} kWh/m²</span>
              </div>
            </DetailListItem>
          </DetailList>
          <div>
            <h3>{{ $t('histogramTitle') }}</h3>
            <HistogramChart
              :key="'rh_histogram'"
              :group-by-key="'el_demand'"
              area-specific
              :highlight-value="building.kpi.el_demand / building.kpi.energy_area"
              :chart-data="chartData"
              class="histogram-chart"
            />
          </div>
        </div>
      </Tab>
      <Tab :title="$t('measuredTitle')">
        <MeasuredKpiChart
          :kpi-type="'ee'"
          class="demand-chart"
          :portfolio="portfolio"
          :query="{ building_id: this.building.id }"
        />
      </Tab>
      <Tab :title="$t('projectionTitle')">
        <ScenarioPicker v-model="selectedScenario" :portfolio="portfolio" />
        <h4>{{ $t('demandByUsage') }}</h4>
        <KpiByUsageChart
          v-if="selectedScenario"
          :kpi-type="'el_demand'"
          class="demand-chart"
          :scenario="selectedScenario"
          :portfolio="portfolio"
          :query="{ building_id: this.building.id }"
        />
      </Tab>
    </Tabs>
  </Modal>
</template>

<script>
import Tabs from '@/components/shared/Tabs.vue'
import Tab from '@/components/shared/Tab.vue'
import ScenarioPicker from '@/components/shared/ScenarioPicker.vue'
import Modal from '@/components/shared/Modal.vue'
import DetailList from '@/components/shared/lists/DetailList.vue'
import DetailListItem from '@/components/shared/lists/DetailListItem.vue'
import KpiByUsageChart from '@/components/report/charts/KpiByUsageChart.vue'
import MeasuredKpiChart from '@/components/report/charts/MeasuredKpiChart.vue'
import HistogramChart from '@/components/portfolio/kpi/charts/HistogramChart.vue'

export default {
  components: {
    Modal,
    ScenarioPicker,
    DetailList,
    DetailListItem,
    MeasuredKpiChart,
    HistogramChart,
    KpiByUsageChart,
    Tabs,
    Tab,
  },

  props: {
    portfolio: {
      type: Object,
      required: true,
    },
    building: {
      type: Object,
      required: true,
    },
    chartData: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      selectedScenario: null,
    }
  },
}
</script>

<style>
.electricity-demand-modal .demand-chart .chart-container {
  min-width: 1000px;
  height: 300px;
}
.electricity-demand-modal .current-kpis {
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-gap: var(--spacing-m);
}
.electricity-demand-modal .histogram-chart {
  height: 220px;
}
</style>
