<i18n>
  {
    "de": {
      "modalTitle": "Entwicklung Energieverbrauch"
    }
  }
  </i18n>

<template>
  <Modal class="c-meters-chart" wide @close="$emit('close', $event)">
    <template #header>
      <h2>{{ $t('modalTitle') }}</h2>
    </template>

    <MeasuredKpiChart
      class="amount-chart"
      :kpi-type="'ee'"
      :portfolio="portfolio"
      :query="{ building_id: this.building.id }"
    />
  </Modal>
</template>

<script>
import Modal from '@/components/shared/Modal.vue'
import MeasuredKpiChart from '@/components/report/charts/MeasuredKpiChart.vue'

export default {
  components: {
    Modal,
    MeasuredKpiChart,
  },

  props: {
    portfolio: {
      type: Object,
      required: true,
    },
    building: {
      type: Object,
      required: true,
    },
  },
}
</script>
