<i18n>
{
  "de": {
    "districtAvailabilityHeader": "Wärmenetze und Wärmequellen",
    "nearSuffix": "entfernt"
  }
}
</i18n>

<template>
  <DetailListItem :title="$t('districtAvailabilityHeader')" expandable @expand="onModalOpen">
    <div v-if="building.energy_grids.state !== 'OK'" class="missing-data">
      {{ $t(`_energyGridsState.${building.energy_grids.state}`) }}
    </div>
    <TextWithIcon v-else :icon="districtAvailabilityIcon" :text="districtAvailabilityText" />
    <template #modal>
      <EnergyGridsModal v-if="modalOpen" :building="building" @close="onModalClose" />
    </template>
  </DetailListItem>
</template>

<script>
import TextWithIcon from '@/components/shared/TextWithIcon.vue'
import DetailListItem from '@/components/shared/lists/DetailListItem.vue'
import EnergyGridsModal from '@/components/building/feasibility/EnergyGridsModal.vue'

export default {
  components: {
    DetailListItem,
    TextWithIcon,
    EnergyGridsModal,
  },

  props: {
    building: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      modalOpen: false,
    }
  },

  computed: {
    districtAvailability() {
      return this.building.energy_grids.energy_grids_simplified
    },

    districtAvailabilityIcon() {
      let colors = {
        HEAT_POTENTIAL_NEAR: 'alert-triangle',
        EXISTING: 'check-circle',
        EXISTING_NEAR: 'alert-triangle',
        PLANNED: 'alert-triangle',
        PLANNED_NEAR: 'alert-triangle',
        EVALUATION: 'alert-triangle',
        EVALUATION_NEAR: 'alert-triangle',
        NONE: 'x-circle',
        ERROR: 'info',
        UNKNOWN: 'help-circle',
      }
      return colors[this.districtAvailability]
    },

    districtAvailabilityText() {
      if (this.building.energy_grids.state !== 'OK') {
        return this.$t('noGisData')
      }
      if (this.building.energy_grids.energy_grids.length > 0) {
        let gridName = ''
        if (this.building.energy_grids.energy_grids[0].name) {
          gridName = ` "${this.building.energy_grids.energy_grids[0].name}"`
        }
        if (this.building.energy_grids.energy_grids[0].distance > 0) {
          let gridDistance = Math.ceil(this.building.energy_grids.energy_grids[0].distance / 10) * 10 + ' m'
          return (
            this.$t(`_gridAvailability.${this.districtAvailability}`) +
            gridName +
            ' (' +
            gridDistance +
            ' ' +
            this.$t('nearSuffix') +
            ')'
          )
        } else {
          return this.$t(`_gridAvailability.${this.districtAvailability}`) + gridName
        }
      } else {
        return this.$t(`_gridAvailability.${this.districtAvailability}`)
      }
    },
  },

  methods: {
    onModalOpen() {
      this.modalOpen = true
    },

    onModalClose() {
      this.modalOpen = false
    },
  },
}
</script>
<style scoped>
.missing-data {
  color: var(--warning-color);
  font-size: var(--font-xs);
}
</style>
