var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"measure-summary"},[(_vm.measure.actions.zones !== null)?_c('div',{staticClass:"partial-measure"},[_c('div',[_vm._v(_vm._s(_vm.$t('changeZones')))]),_c('div')]):_vm._e(),(_vm.measure.actions.pv !== null && _vm.measure.next_state.pv.sections.length > 0)?_c('div',{staticClass:"partial-measure"},[_c('div',[_vm._v(_vm._s(_vm.$t('pvInstallation'))+" "+_vm._s(_vm.formatNumber(_vm.pvCapacity, 0))+" kW")]),_c('div',[_vm._v(_vm._s(_vm.formatNumber(_vm.measure.costs.pv.costs, -3))+" CHF")])]):_vm._e(),(_vm.measure.actions.pv !== null && _vm.measure.next_state.pv.sections.length === 0)?_c('div',{staticClass:"partial-measure"},[_c('div',{staticClass:"partial-measure__warning"},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('pvInstallation'))+" "+_vm._s(_vm.formatNumber(0, 0))+" kW ")]),_c('DataWarning',[_vm._v(_vm._s(_vm.$t('noSectionsWarning')))])],1),_c('div',[_vm._v(_vm._s(_vm.formatNumber(0, -3))+" CHF")])]):_vm._e(),(_vm.measure.actions.heating !== null)?_c('div',{staticClass:"partial-measure"},[_c('div',[_vm._v(" "+_vm._s(_vm.$t('heatingReplacement'))+" "+_vm._s(_vm.getHeatingTypeName(_vm.measure.actions.heating.heaters[0].heating_type_id))+" ")]),_c('div',[_vm._v(_vm._s(_vm.formatNumber(_vm.measure.costs.heating.costs, -3))+" CHF")])]):_vm._e(),(
      _vm.measure.actions.envelope !== null &&
      _vm.measure.actions.envelope.windows !== null &&
      _vm.measure.actions.envelope.windows.standard !== 'NONE'
    )?_c('div',{staticClass:"partial-measure"},[_c('div',[_vm._v(_vm._s(_vm.$t('windowsReplacement')))]),_c('div',[_vm._v(_vm._s(_vm.formatNumber(_vm.measure.costs.windows.costs, -3))+" CHF")])]):_vm._e(),(
      _vm.measure.actions.envelope !== null &&
      _vm.measure.actions.envelope.walls !== null &&
      _vm.measure.actions.envelope.walls.standard !== 'NONE'
    )?_c('div',{staticClass:"partial-measure"},[_c('div',[_vm._v(_vm._s(_vm.$t('wallsRenovation')))]),_c('div',[_vm._v(_vm._s(_vm.formatNumber(_vm.measure.costs.walls.costs, -3))+" CHF")])]):_vm._e(),(
      _vm.measure.actions.envelope !== null &&
      _vm.measure.actions.envelope.roof !== null &&
      _vm.measure.actions.envelope.roof.standard !== 'NONE'
    )?_c('div',{staticClass:"partial-measure"},[_c('div',[_vm._v(_vm._s(_vm.$t('roofRenovation')))]),_c('div',[_vm._v(_vm._s(_vm.formatNumber(_vm.measure.costs.roof.costs, -3))+" CHF")])]):_vm._e(),(
      _vm.measure.actions.envelope !== null &&
      _vm.measure.actions.envelope.basement !== null &&
      _vm.measure.actions.envelope.basement.standard !== 'NONE'
    )?_c('div',{staticClass:"partial-measure"},[_c('div',[_vm._v(_vm._s(_vm.$t('basementRenovation')))]),_c('div',[_vm._v(_vm._s(_vm.formatNumber(_vm.measure.costs.basement.costs, -3))+" CHF")])]):_vm._e(),(_vm.measure.actions.electricity !== null)?_c('div',{staticClass:"partial-measure"},[_c('div',[_vm._v(_vm._s(_vm.$t('energyGridRenovation')))]),_c('div',[_vm._v(_vm._s(_vm.formatNumber(_vm.measure.costs.electricity.costs, -3))+" CHF")])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }