<i18n>
{
  "de": {
    "usageSummaryHeader": "Fläche und Nutzung",
    "missingZones": "Keine Zonen definiert",
    "hasUnknownZoneUsages": "Nutzung fehlt",
    "missingEnergyArea": "Fläche fehlt",
    "usageEstimatedLabel": "Nutzung unsicher",
    "areaEstimatedLabel": "Flächen unsicher",
    "areaGisLabel": "Flächen aus GIS-Daten abgeleitet"
  }
}
</i18n>

<template>
  <DetailListItem class="zones-summary-row" :title="$t('usageSummaryHeader')" expandable @expand="onModalOpen">
    <Box className="zones-summary-contents">
      <Box v-if="totalEnergyArea" className="pill"> {{ formatNumber(totalEnergyArea, 0) }} m² </Box>
      <Box v-for="(name, index) in zonesList" :key="index" className="pill">
        {{ name }}
      </Box>
      <MissingDataLabel v-if="areaFromGis">
        {{ $t('areaGisLabel') }}
      </MissingDataLabel>
      <MissingDataLabel v-if="totalEnergyArea === undefined">
        {{ $t('missingEnergyArea') }}
      </MissingDataLabel>
      <!-- <MissingDataLabel v-if="hasUnknownZoneUsages">
        {{ $t('hasUnknownZoneUsages')}}
      </MissingDataLabel> -->
      <MissingDataLabel v-if="building.initial_state.zones.zones.length === 0">
        {{ $t('missingZones') }}
      </MissingDataLabel>
    </Box>

    <template #modal>
      <ZonesModal v-if="isModalOpen" :building="building" @close="onModalClose" />
    </template>
  </DetailListItem>
</template>

<script>
import ModalMixin from '@/components/shared/ModalMixin.vue'
import Box from '@/components/cui/layout/Box.vue'

import DetailListItem from '@/components/shared/lists/DetailListItem.vue'
import MissingDataLabel from '@/components/shared/MissingDataLabel.vue'
import ZonesModal from '@/components/building/overview/ZonesModal.vue'

export default {
  mixins: [
    //
    ModalMixin,
  ],

  components: {
    Box,
    DetailListItem,
    MissingDataLabel,
    ZonesModal,
  },

  props: {
    building: {
      type: Object,
      required: true,
    },
  },

  computed: {
    totalEnergyArea() {
      const sum = this.building.initial_state.zones.zones.reduce((pv, cv) => {
        if (cv.energy_area.deduced) {
          return pv + parseFloat(cv.energy_area.deduced)
        } else if (cv.energy_area) {
          return pv + parseFloat(cv.energy_area)
        } else {
          return pv
        }
      }, 0)
      if (Number.isNaN(sum)) {
        return undefined
      }
      return sum
    },

    areaFromGis() {
      if (this.building.initial_state.result !== null) {
        return this.building.initial_state.zones.zones.reduce(
          (pv, cv) =>
            pv + (cv.energy_area.source === 'GWR_EXTRAPOLATION' || cv.energy_area.source === 'SONNENDACH_EXTRAPOLATION')
              ? 1
              : 0,
          0
        )
      }
      return undefined
    },

    hasUnknownZoneUsages() {
      return this.building.initial_state.zones.zones.find((z) => z.usage === 'UNKNOWN')
    },

    zonesList() {
      let result = []
      for (let zoneIdx = 0; zoneIdx < this.building.initial_state.zones.zones.length; zoneIdx++) {
        const zone = this.building.initial_state.zones.zones[zoneIdx]
        const zoneName = this.$t(`_usageTypes.${zone.usage}`)
        const zoneShare =
          zone.energy_area.deduced && this.formatNumber((zone.energy_area.deduced / this.totalEnergyArea) * 100, 0)
        if (Number.isNaN(zoneShare) || zoneShare === false || this.building.initial_state.zones.zones.length === 1) {
          result.push(zoneName)
        } else {
          result.push(`${zoneShare} % ${zoneName}`)
        }
      }
      return result
    },
  },
}
</script>

<style lang="scss" scoped>
.zones-summary-row {
  & .zones-summary-contents {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--spacing-xs);
    align-items: center;

    & .pill {
      display: flex;
      align-items: center;
      gap: var(--spacing-xxs);
      padding: 0 var(--spacing-s);
      border: var(--box-border);
      border-radius: 13px;
    }
  }
}
</style>
