<i18n>
{
  "de": {
    "pvTitle": "Photovoltaik-Potenzial",
    "reloadLabel": "Dachflächen neu abfragen",
    "pendingMessage": "Daten werden abgefragt...",
    "lastUpdateLabel": "Quelle: Bundesamt für Energie (BFE). Aktualisiert am"
  }
}
</i18n>

<template>
  <Modal wide @close="$emit('close', $event)">
    <template #header>
      <h2>{{ $t('pvTitle') }}</h2>
    </template>
    <div v-if="error">
      <p>{{ error }}</p>
    </div>
    <div v-if="pending">
      <p>{{ $t('pendingMessage') }}</p>
    </div>
    <div v-else>
      <PvPotentialTable v-if="building.roof.state === 'OK'" :building="building" />
      <div v-else class="missing-data">
        {{ $t(`_roofState.${building.roof.state}`) }}
      </div>
      <ButtonWrapper v-if="!pending">
        <p v-if="building.roof.state === 'OK'" class="last-update-label">
          {{ $t('lastUpdateLabel') }}: {{ lastUpdateDate }}
        </p>
        <button v-if="getBuildingPermission('REFRESH_ROOF')" class="button" @click="onReload">
          {{ $t('reloadLabel') }}
        </button>
      </ButtonWrapper>
    </div>
  </Modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import Modal from '@/components/shared/Modal.vue'
import ButtonWrapper from '@/components/shared/ButtonWrapper.vue'
import PvPotentialTable from '@/components/building/feasibility/PvPotentialTable.vue'

export default {
  components: {
    Modal,
    ButtonWrapper,
    PvPotentialTable,
  },

  props: {
    building: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      pending: null,
      error: null,
    }
  },

  computed: {
    ...mapGetters({
      getBuildingPermission: 'permissions/getBuildingPermission',
    }),

    lastUpdateDate() {
      return new Date(this.building.roof.last_update).toLocaleDateString('de-DE')
    },
  },

  methods: {
    ...mapActions({
      queryRoofTiles: 'building/queryRoofTiles',
    }),

    async onReload() {
      this.pending = true
      try {
        this.error = null
        await this.queryRoofTiles({
          id: this.building.id,
        })
      } catch (error) {
        this.error = error
      }
      this.pending = false
    },
  },
}
</script>

<style scoped>
.missing-data {
  color: var(--warning-color);
}

.last-update-label {
  margin-bottom: 5px;
}
</style>
