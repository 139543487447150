<i18n>
{
  "de": {
    "title": "Energie"
  }
}
</i18n>

<template>
  <Card>
    <CardHeader :title="$t('title')" />
    <CardContent>
      <List className="kpi-overview" divider>
        <!-- Sankey -->
        <!-- Retained for further development  -->
        <!-- <EnergySummaryRow :building="building" :chart-data="chartData" /> -->
        <KpiSummaryRow
          v-for="indicator in energySustainabilityIndicatorIdentifiers"
          :key="indicator"
          :kpi-type="indicator"
          :building="building"
          :portfolio="portfolio"
          :chart-data="chartData"
        />
        <RhSummaryRow :building="building" :portfolio="portfolio" :chart-data="chartData" />
        <DhwSummaryRow :building="building" />
        <HeatingPowerSummaryRow :building="building" />
        <ElectricityDemandSummaryRow :building="building" :portfolio="portfolio" :chart-data="chartData" />
        <ElectricityProductionSummaryRow :building="building" :portfolio="portfolio" :chart-data="chartData" />
      </List>
    </CardContent>
  </Card>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import Card from '@/components/cui/surfaces/Card.vue'
import CardHeader from '@/components/cui/surfaces/CardHeader.vue'
import CardContent from '@/components/cui/surfaces/CardContent.vue'
import List from '@/components/cui/data-display/List.vue'
// Retained for further development:
// import EnergySummaryRow from '@/components/building/kpi/EnergySummaryRow.vue'
import KpiSummaryRow from '@/components/building/kpi/KpiSummaryRow.vue'
import RhSummaryRow from '@/components/building/kpi/RhSummaryRow.vue'
import DhwSummaryRow from '@/components/building/kpi/DhwSummaryRow.vue'
import HeatingPowerSummaryRow from '@/components/building/kpi/HeatingPowerSummaryRow.vue'
import ElectricityDemandSummaryRow from '@/components/building/kpi/ElectricityDemandSummaryRow.vue'
import ElectricityProductionSummaryRow from '@/components/building/kpi/ElectricityProductionSummaryRow.vue'

export default {
  components: {
    Card,
    CardHeader,
    CardContent,
    List,
    // Retained for further development:
    // EnergySummaryRow,
    KpiSummaryRow,
    RhSummaryRow,
    DhwSummaryRow,
    HeatingPowerSummaryRow,
    ElectricityDemandSummaryRow,
    ElectricityProductionSummaryRow,
  },

  props: {
    portfolio: {
      type: Object,
    },
    building: {
      type: Object,
      required: true,
    },
    chartData: {
      type: Array,
      required: true,
    },
  },

  computed: {
    ...mapState('portfolio', ['energySustainabilityIndicatorIdentifiers']),
  },

  methods: {
    ...mapGetters({
      isDefaultSustainabilityIndicator: 'portfolio/isDefaultSustainabilityIndicator',
    }),
  },
}
</script>
