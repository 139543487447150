<i18n>
{
  "de": {
    "header": "Stromproduktion",
    "ownUse": "Eigenverbrauch"
  }
}
</i18n>

<template>
  <DetailListItem :title="$t('header')" expandable @expand="onModalOpen">
    <div v-if="building.kpi.el_production > 0" class="three-columns">
      <span>{{ formatNumber(building.kpi.el_production / 1000, 1) }} MWh</span>
      <span
        >{{ $t('ownUse') }}: {{ formatNumber((building.kpi.el_ownuse / building.kpi.el_production) * 100, 0) }} %</span
      >
    </div>
    <div v-else>-</div>
    <template #modal>
      <ElectricityProductionModal v-if="modalOpen" :building="building" :portfolio="portfolio" :chart-data="chartData" @close="onModalClose" />
    </template>
  </DetailListItem>
</template>

<script>
import DetailListItem from '@/components/shared/lists/DetailListItem.vue'
import ElectricityProductionModal from '@/components/building/kpi/ElectricityProductionModal.vue'

export default {
  components: {
    DetailListItem,
    ElectricityProductionModal,
  },

  props: {
    portfolio: {
      type: Object,
    },
    building: {
      type: Object,
      required: true,
    },
    chartData: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      modalOpen: false,
    }
  },

  methods: {
    onModalOpen() {
      this.modalOpen = true
    },

    onModalClose() {
      this.modalOpen = false
    },
  },
}
</script>
