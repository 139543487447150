<template>
  <div :class="`cui-card-header ${className}`">
    <div class="cui-card-header-title">{{ title }}</div>
    <slot name="action" class="cui-card-header-action"></slot>
  </div>
</template>

<script>
export default {
  name: 'Card',

  props: {
    className: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss" scoped>
.cui-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--spacing-xs);
  width: 100%;
  font-weight: 700;
  padding-right: 10px;
  border-bottom: var(--box-border);

  & .cui-card-header-title {
    padding: 18px 10px;
  }
}
</style>
