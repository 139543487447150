<i18n>
{
  "de": {
    "gisError": "GIS-Abfragefehler",
    "properties": "Liegenschaften",
    "deleteBuilding": "Liegenschaft löschen",
    "deleteConfirmation": "Wollen Sie diese Liegenschaft wirklich löschen?",
    "exportBuilding": "JSON-Export",
    "pvProfitabilityTitle": "Variantenvergleich Photovoltaik",
    "measuresTitle": "Massnahmen",
    "hasBadMeasureList": "Folgende Szenarien haben eine ungültige Massnahmenliste:",
    "infoBadMeasureList": "Erklärung: Szenario editierte Massnahmen dürfen nur nach allen umgesetzten und geplanten Massnahmen auftreten.",
    "isBeingCalculated": "wird berechnet ...",
    "map": "Karte",
    "bubble": "Streudiagramm",
    "allowEdit": "Admin: Erweitertes Bearbeiten",
    "disallowEdit": "Admin: Zurück zum eingeschränkten Bearbeiten"
  }
}
</i18n>

<template>
  <MainLayout :portfolio="portfolio" :ready="portfolioReady && buildingReady" class="p-building-page">
    <template #default>
      <!-- Header -->
      <header class="building-header">
        <template v-if="building">
          <PageTitle :title="building.description" :portfolio="portfolio" :show-separator="false">
            <template #default>
              <div>/</div>
              <router-link :to="`/portfolios/${portfolio.id}/buildings`">{{ $t('properties') }}</router-link>
            </template>
            <template #details>
              <div class="building-page-queue">
                <BuildingQueuedIndicator
                  v-if="buildingSimulationQueue(portfolio.id).includes(building.id)"
                  :text="$tc('isBeingCalculated')"
                />
                <span v-if="building.zip_code">{{ building.zip_code }}</span>
                <span v-if="building.municipality">{{ building.municipality }}</span>
                <span v-if="!building.municipality && building.location_info.state === 'OK'">
                  {{ building.location_info.data.municipality }}
                </span>
                <span class="identifier">
                  {{ building.identifier }}
                </span>
              </div>
            </template>
            <template #actions>
              <!-- Admin edit override -->
              <button
                v-if="canSwitchPermissions"
                class="button"
                :class="{ danger: isAdminEditOverrideActive(building.id) }"
                @click="onSwitchPermissions"
              >
                {{ isAdminEditOverrideActive(building.id) ? $t('disallowEdit') : $t('allowEdit') }}
              </button>
            </template>
          </PageTitle>
        </template>
      </header>

      <!-- Building visualization: side-by-side map and bubble scatterplot -->
      <div class="building-visualization building-visualization--split">
        <!-- Map -->
        <BuildingMap
          v-if="building && building.latitude !== null && building.longitude !== null"
          :building="building"
          class="building-map"
        />
        <!-- Bubble Chart -->
        <BubbleSummaryChart v-if="building" :building="building" :chart-data="chartData" />
      </div>
      <!-- Building visualization: toggle between map and bubble scatterplot -->
      <div class="building-visualization building-visualization--consolidated">
        <!-- Toggle buttons -->
        <nav class="round-buttons">
          <button
            type="button"
            class="button"
            :class="{ selected: buildingMapAndKpisMode === 'map' }"
            @click="setBuildingMapAndKpisMode('map')"
          >
            <img src="/icons/map.svg" />
            <span>{{ $t('map') }}</span>
          </button>
          <button
            type="button"
            class="button"
            :class="{ selected: buildingMapAndKpisMode === 'bubble' }"
            @click="setBuildingMapAndKpisMode('bubble')"
          >
            <img src="/icons/bubble.svg" />
            <span>{{ $t('bubble') }}</span>
          </button>
        </nav>
        <!-- Map -->
        <BuildingMap
          v-if="
            building && building.latitude !== null && building.longitude !== null && buildingMapAndKpisMode === 'map'
          "
          :building="building"
          class="building-map"
        />
        <!-- Bubble Chart -->
        <BubbleSummaryChart
          v-if="building && buildingMapAndKpisMode === 'bubble'"
          :building="building"
          :chart-data="chartData"
        />
      </div>

      <!-- Overview -->
      <OverviewSection
        v-if="building"
        class="building-page-section"
        :building="building"
        :portfolio="portfolio"
        :chart-data="chartData"
      />

      <!-- Meters -->
      <MetersList v-if="building" :building="building" :portfolio="portfolio" />

      <!-- Measures -->
      <br /><br />
      <h2>{{ $t('measuresTitle') }}</h2>
      <div v-if="badScenarioList.length" class="has-bad-measure-list">
        <p>{{ $t('hasBadMeasureList') }}</p>
        <ul>
          <li v-for="(s, idx) in badScenarioList" :key="idx">{{ s }}</li>
        </ul>
        <p>{{ $t('infoBadMeasureList') }}</p>
      </div>
      <MeasuresList v-if="building" :building="building" :portfolio="portfolio" />

      <!-- PV -->
      <div v-if="portfolio.enabled_features.PV_PROFITABILITY">
        <br /><br />
        <h2>{{ $t('pvProfitabilityTitle') }}</h2>
        <PvOverview v-if="building" :building="building" :portfolio="portfolio" />
      </div>

      <!-- Buttons: Delete / Export -->
      <ButtonWrapper v-if="building">
        <button v-if="getBuildingPermission('DELETE')" class="button" @click="onDelete">
          {{ $t('deleteBuilding') }}
        </button>
        <button v-if="getBuildingPermission('VIEW')" class="button" @click="onExport">
          {{ $t('exportBuilding') }}
        </button>
      </ButtonWrapper>
    </template>
  </MainLayout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import MainLayout from '@/pages/layouts/MainLayout.vue'
import AllPortfoliosMixin from '@/pages/vuex-mixins/AllPortfoliosMixin.vue'
import PortfolioMixin from '@/pages/vuex-mixins/PortfolioMixin.vue'
import BuildingMixin from '@/pages/vuex-mixins/BuildingMixin.vue'
import ChartDataMixin from '@/pages/vuex-mixins/ChartDataMixin.vue'

import BuildingMap from '@/components/building/map/BuildingMap.vue'
import BubbleSummaryChart from '@/components/building/kpi/BubbleSummaryChart.vue'
import OverviewSection from '@/components/building/OverviewSection.vue'
import PvOverview from '@/components/building/PvOverview.vue'
import MetersList from '@/components/building/meters/MetersList.vue'
import MeasuresList from '@/components/building/measures/MeasuresList.vue'
import ButtonWrapper from '@/components/shared/ButtonWrapper.vue'
import PageTitle from '@/components/shared/PageTitle.vue'
import BuildingQueuedIndicator from '@/components/shared/BuildingQueuedIndicator.vue'

import exportService from '@/services/export-service.js'

export default {
  name: 'buildingDetails',

  mixins: [
    AllPortfoliosMixin, // Provides: allPortfolios, allPortfoliosReady
    PortfolioMixin, // Provides: portfolio, portfolioLoading, portfolioReady
    BuildingMixin, // Provides: building, buildingReady, buildingLoading
    ChartDataMixin, // Provides: chartData, chartDataLoading, chartDataLoaded
  ],

  components: {
    BuildingQueuedIndicator,
    ButtonWrapper,
    MainLayout,
    BuildingMap,
    BubbleSummaryChart,
    PvOverview,
    OverviewSection,
    MetersList,
    MeasuresList,
    PageTitle,
  },

  data() {
    return {
      pageTitle: null,
      deletePending: false,
      deleteError: null,
      exportPending: false,
      exportError: null,
    }
  },

  computed: {
    ...mapGetters({
      buildingSimulationQueue: 'queue/buildingSimulationQueue',
      getBuildingPermission: 'permissions/getBuildingPermission',
      canSwitchPermissions: 'permissions/canSwitchPermissions',
      isAdminEditOverrideActive: 'permissions/isAdminEditOverrideActive',
      getBuildingMapAndKpisMode: 'ui/getBuildingMapAndKpisMode',
    }),

    buildingMapAndKpisMode() {
      return this.getBuildingMapAndKpisMode()
    },

    badScenarioList() {
      const badScenarios = []
      this.portfolio.scenarios.forEach((s) => {
        if (!this.hasGoodMeasureOrder(this.building, s.id)) {
          badScenarios.push(s.description)
        }
      })
      return badScenarios
    },
  },

  watch: {
    building() {
      this.onBuildingUpdated()
    },
  },

  mounted() {
    if (this.building_id) {
      this.__loadBuildingDetailsById(this.building_id)
    }
  },

  methods: {
    ...mapActions({
      deleteBuilding: 'building/deleteBuilding',
      refreshBuildings: 'portfolioBuildingsForList/refresh',
      switchBuildingPermissions: 'permissions/switchBuildingPermissions',
      setBuildingMapAndKpisMode: 'ui/setBuildingMapAndKpisMode',
      __loadBuildingDetailsById: 'building/refreshDetailsById',
    }),

    onExport() {
      exportService.exportBuildingJson(this.building)
    },

    async onDelete() {
      const confirmationText = this.$t('deleteConfirmation')
      const portfolioId = this.building.portfolio_id
      if (confirm(confirmationText)) {
        try {
          this.deletePending = true
          this.deleteError = null
          await this.deleteBuilding({
            buildingId: this.building.id,
            portfolioId: this.portfolio.id,
          })
        } catch (error) {
          this.deleteError = error
        }
        this.deletePending = false
        await this.refreshBuildings()
        this.$router.push({ name: 'portfolioBuildings', params: { portfolio_id: portfolioId } })
      }
    },

    hasGoodMeasureOrder(building, scenarioId) {
      const SCENARIO_SCOPED_MEASURE_TYPE = ['SCENARIO_EDITED']
      const BUILDING_SCOPED_MEASURE_TYPE = ['COMPLETED', 'PLANNED']
      let scenarioScopedHasOccurred = false
      if (building) {
        for (const measure of building.measures) {
          // building.measures.forEach(measure => {
          if (
            BUILDING_SCOPED_MEASURE_TYPE.includes(measure.phase) ||
            (measure.scenario_id === scenarioId && SCENARIO_SCOPED_MEASURE_TYPE.includes(measure.phase))
          ) {
            if (!scenarioScopedHasOccurred) {
              if (SCENARIO_SCOPED_MEASURE_TYPE.includes(measure.phase)) {
                scenarioScopedHasOccurred = true
              }
            } else {
              if (BUILDING_SCOPED_MEASURE_TYPE.includes(measure.phase)) {
                return false
              }
            }
          }
        }
      }
      return true
    },

    onBuildingUpdated() {
      this.pageTitle = this.building?.description
      this.$emit('updateHead')
    },

    onSwitchPermissions() {
      this.switchBuildingPermissions(this.building)
    },
  },

  head() {
    return {
      title: () => {
        if (this.pageTitle) {
          return { inner: `${this.pageTitle}` }
        }
        if (this.building) {
          return { inner: this.building.description }
        }
      },
    }
  },
}
</script>

<style lang="scss">
.p-building-page {
  & .building-page-section {
    margin-bottom: var(--spacing-l);
  }
}

.has-bad-measure-list {
  padding: 8px 8px 8px 12px;
  background: #ff6f6f;
  border: var(--box-border);
  border-radius: var(--box-radius);
  margin-bottom: var(--spacing-s);

  & ul {
    margin-top: 10px;
  }

  & li {
    margin-left: 40px;
    list-style: disc;
  }
}

.building-page-queue {
  display: flex;
  gap: 5px;

  & .identifier {
    color: #888;
    margin-left: 4px;
  }
}
</style>

<style lang="scss" scoped>
.building-visualization {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: var(--spacing-m);
  border: 1px solid var(--hairline-color);
  border-radius: var(--box-radius);
  overflow: hidden;

  &.building-visualization--split {
    display: flex;

    @media screen and (max-width: 960px) {
      display: none;
    }

    & > * {
      width: 50%;
    }
  }

  &.building-visualization--consolidated {
    display: none;

    @media screen and (max-width: 960px) {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      height: 480px;

      & .c-building-map {
        width: 100%;
        height: 400px;
      }

      & .c-bubble-summary-chart {
        width: 100%;
        height: 400px;
      }
    }

    & nav.round-buttons {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: var(--spacing-xs);
      height: 64px;
      width: 100%;
      border-bottom: 1px solid var(--hairline-color);

      & button {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: var(--spacing-s);
        border: 2px solid var(--hairline-color);
        background-color: #fff;
        border-radius: 500px;
        line-height: 16px;
        min-height: 40px;
        margin: 0;

        & img {
          height: 16px;
          width: 16px;
          vertical-align: sub;
        }

        & img + * {
          margin-left: 5px;
        }

        &.selected {
          border: 2px solid #333;
        }
      }
    }
  }
}

.danger {
  background-color: var(--warning-color);
}
</style>
