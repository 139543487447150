<template>
  <EditContainer
    :bind-form-state="formState"
    :allow-edit="getBuildingPermission('EDIT_ZONES')"
    class="edit-zones"
    @container="onFormEvent"
  >
    <EditZonesTable
      v-model="model.zones"
      :zones="building.initial_state.zones.zones"
      :edit="isEditing"
      @input="onZonesChange"
      @validation="onValidation($event, 'zones-table')"
    />
  </EditContainer>
</template>

<script>
import { mapActions } from 'vuex'

import ApiFormMixin from '@/components/shared/forms/ApiFormMixin.vue'

import EditContainer from '@/components/shared/forms/EditContainer.vue'
import EditZonesTable from '@/components/building/edit/EditZonesTable.vue'

export default {
  mixins: [
    ApiFormMixin,
    /* ApiFormMixin:
      - Requires implementation of the following methods: resetModel, saveHandler, isValid (optional)
      - Provides: model, isValid, isEditing, formState, onFormEvent, onValidation,
                  getBuildingPermission, getPortfolioPermission
    */
  ],

  components: {
    EditContainer,
    EditZonesTable,
  },

  props: {
    building: {
      type: Object,
      required: true,
    },
  },

  methods: {
    ...mapActions({
      updateBuilding: 'building/updateBuilding',
    }),

    onZonesChange(zones) {
      this.model.zones = zones
    },

    resetModel() {
      this.model = {
        zones: [],
      }
      for (let i = 0; i < this.building.initial_state.zones.zones.length; i++) {
        const zone = this.building.initial_state.zones.zones[i]
        this.model.zones.push({ ...zone })
      }
    },

    async saveHandler() {
      await this.updateBuilding({
        id: this.building.id,
        initial_state: {
          zones: this.model,
        },
      })
    },
  },
}
</script>
