<template>
  <div :class="`cui-grid ${className}`" :style="computedStyle">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'Grid',

  props: {
    itemWidth: {
      type: String,
      default: '1fr',
    },
    className: {
      type: String,
      default: '',
    },
    spacing: {
      type: String,
      default: '10px',
    },
  },

  computed: {
    computedStyle() {
      return {
        display: 'grid',
        gridTemplateColumns: `repeat(auto-fill, minmax(${this.itemWidth}, 1fr))`,
        gap: this.spacing,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.cui-grid {
  width: 100%;
}
</style>
