<i18n>
{
  "de": {
    "statusHeader": "Status",
    "activeText": "Aktiv",
    "inactiveText": "Inaktiv",
    "from": "seit"
  }
}
</i18n>

<template>
  <DetailListItem :title="$t('statusHeader')" expandable @expand="onModalOpen">
    <div class="content-wrapper">
      <div>
        {{ `${$t(isActive ? 'activeText' : 'inactiveText')} ${yearLabel}` }}
      </div>
    </div>

    <template #modal>
      <Modal v-if="isModalOpen" @close="onModalClose">
        <template #header>
          <h2>{{ $t('statusHeader') }}</h2>
        </template>
        <EditActive :building="building" />
      </Modal>
    </template>
  </DetailListItem>
</template>

<script>
import ModalMixin from '@/components/shared/ModalMixin.vue'

import Modal from '@/components/shared/Modal.vue'
import EditActive from '@/components/building/edit/EditActive.vue'
import DetailListItem from '@/components/shared/lists/DetailListItem.vue'

export default {
  mixins: [
    //
    ModalMixin,
  ],

  components: {
    Modal,
    EditActive,
    DetailListItem,
  },

  props: {
    building: {
      type: Object,
      required: true,
    },
  },

  computed: {
    buildingYear() {
      return this.building.initial_state?.result?.envelope?.building_year?.value
    },

    isActive() {
      const activeFrom = this.building.active_from || this.buildingYear || 0
      const activeTo = this.building.active_to || 9999
      const currentYear = new Date().getFullYear()

      return activeFrom && activeFrom <= currentYear && activeTo >= currentYear
    },

    yearLabel() {
      const activeFrom = this.building.active_from
      const activeTo = this.building.active_to
      const buildingYear = this.buildingYear
      const isActive = this.isActive

      if (activeFrom && activeTo) {
        // If both are set, just show both no matter if active or not
        return `(${activeFrom} - ${activeTo})`
      } else if (!activeFrom && activeTo) {
        // If activeTo is set, don't show 'from', just show building year and activeTo no matter if active or not
        return `(${buildingYear} - ${activeTo})`
      } else if (isActive && activeFrom && !activeTo) {
        // If active and activeFrom is set, show 'from' and activeFrom
        return `(${this.$t('from')} ${activeFrom})`
      } else if (isActive && !activeFrom && buildingYear) {
        // If active and no activeFrom is set, show 'from' and use building year
        return `(${this.$t('from')} ${buildingYear})`
      }

      return ''
    },
  },
}
</script>

<style scoped lang="scss">
.content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-xxs);
  padding-bottom: var(--spacing-xxs);
}
</style>
