<template>
  <VPopover :class="`cui-tooltip ${className}`" trigger="hover">
    <div class="trigger-wrapper">
      <slot />
    </div>
    <template slot="popover">
      <main>{{ title }}</main>
    </template>
  </VPopover>
</template>

<script>
import { VPopover } from 'v-tooltip'

export default {
  name: 'Tooltip',

  components: {
    VPopover,
  },

  props: {
    className: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.cui-tooltip {
  display: flex;

  & .trigger-wrapper {
    display: flex;
    align-items: center;
  }
}
</style>
