<!--
Modal Wrapper for EditMeasure
-->
<i18n>
{
  "de": {
    "editMeasureTitle": "Massnahme",
    "createMeasureTitle": "Massnahme erstellen",
    "editScenarioMeasureTitle": "Massnahme basierend auf Szenario erstellen"
  }
}
</i18n>
<template>
  <Modal wide @close="$emit('close', $event)">
    <template #header>
      <h1 v-if="!measure">{{ $t('createMeasureTitle') }}</h1>
      <h1 v-else-if="measure && measure.phase === 'SCENARIO'">{{ $t('editScenarioMeasureTitle') }}</h1>
      <h1 v-else>{{ $t('editMeasureTitle') }}</h1>
    </template>
    <EditMeasure
      :measure="measure"
      :building="building"
      :last-kpi="lastKpi"
      :portfolio="portfolio"
      :selected-scenario="selectedScenario"
      @cancel="onCancel"
      @created="onCreated"
    />
  </Modal>
</template>

<script>
import Modal from '@/components/shared/Modal.vue'
import EditMeasure from '@/components/building/measures/edit/EditMeasure.vue'

export default {
  components: {
    Modal,
    EditMeasure,
  },

  props: {
    selectedScenario: {
      type: Object,
      required: true,
    },
    building: {
      type: Object,
    },
    portfolio: {
      type: Object,
    },
    measure: {
      // edit existing measure
      type: Object,
    },
    lastKpi: {
      type: Object,
    },
  },

  methods: {
    onCancel() {
      if (!this.measure) {
        // Close dialog if adding new is canceled
        this.$emit('close')
      }
    },

    onCreated() {
      this.$emit('close') // Close modal after creating new measure
    },
  },
}
</script>
