<i18n>
{
  "de": {
    "title": "Emissionen"
  }
}
</i18n>

<template>
  <Card>
    <CardHeader :title="$t('title')" />
    <CardContent>
      <List className="kpi-overview" divider>
        <KpiSummaryRow
          v-for="indicator in emissionsSustainabilityIndicatorIdentifiers"
          :key="indicator"
          :kpi-type="indicator"
          :building="building"
          :portfolio="portfolio"
          :chart-data="chartData"
        />
      </List>
    </CardContent>
  </Card>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import Card from '@/components/cui/surfaces/Card.vue'
import CardHeader from '@/components/cui/surfaces/CardHeader.vue'
import CardContent from '@/components/cui/surfaces/CardContent.vue'
import List from '@/components/cui/data-display/List.vue'
import KpiSummaryRow from '@/components/building/kpi/KpiSummaryRow.vue'

export default {
  components: {
    Card,
    CardHeader,
    CardContent,
    List,
    KpiSummaryRow,
  },

  props: {
    portfolio: {
      type: Object,
    },
    building: {
      type: Object,
      required: true,
    },
    chartData: {
      type: Array,
      required: true,
    },
  },

  computed: {
    ...mapState('portfolio', ['emissionsSustainabilityIndicatorIdentifiers']),
  },

  methods: {
    ...mapGetters({
      isDefaultSustainabilityIndicator: 'portfolio/isDefaultSustainabilityIndicator',
    }),
  },
}
</script>
