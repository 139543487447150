<script>
import BaseLayer from '@/components/building/map/BaseLayer.vue'

export default {
  components: {
    BaseLayer,
  },

  extends: BaseLayer,

  computed: {
    sourceConfig() {
      let features = []
      if (this.building.gwr.state === 'OK') {
        for (let i = 0; i < this.building.gwr.entries.length; i++) {
          let entry = this.building.gwr.entries[i]
          features.push({
            id: i,
            type: 'Feature',
            geometry: entry['geometry'],
            properties: {
              ...entry['properties'],
              description: entry['properties']['label'],
            },
          })
        }
      }
      return {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: features,
        },
      }
    },

    layerConfig() {
      return {
        type: 'symbol',
        source: this.sourceId,
        paint: {
          'text-color': ['case', ['boolean', ['feature-state', 'hover'], false], '#000000', '#222222'],
        },
        layout: {
          'icon-image': 'circle_dark_grey_6',
          'icon-size': 1,
        },
        minzoom: 14,
      }
    },
  },
}
</script>
