<i18n>
{
  "de": {
      "addNewLabel": "Massnahme hinzufügen",
      "yearTitle": "Jahr",
      "actionsTitle": "Massnahmen",
      "ghgTitle": "Scope 1-2 Emissionen",
      "rhTitle": "Raumwärme",
      "peTitle": "Primärenergie",
      "noMeasures": "Keine Massnahmen vorhanden"
  }
}
</i18n>

<template>
  <div class="c-measures-list">
    <ButtonWrapper>
      <ScenarioPicker v-model="selectedScenario" :portfolio="portfolio" class="scenario-picker" />
      <button v-if="getBuildingPermission('ADD_MEASURES')" class="button" @click.prevent="onAdd">
        {{ $t('addNewLabel') }}
      </button>
    </ButtonWrapper>

    <div v-if="selectedScenario && !measures.length">{{ $t('noMeasures') }}</div>

    <DetailList v-if="selectedScenario && measures.length" has-header>
      <template #header>
        <span></span>
        <span>{{ $t('yearTitle') }}</span>
        <span>{{ $t('actionsTitle') }}</span>
        <span>{{ $t('ghgTitle') }}</span>
        <span>{{ $t('peTitle') }}</span>
        <span>{{ $t('rhTitle') }}</span>
        <span></span>
      </template>

      <MeasureRow
        v-for="(measure, index) in measures"
        :key="index"
        :measure="measure"
        :lastKpi="index > 0 ? measures[index - 1].kpi : building.kpi"
        :building="building"
        :portfolio="portfolio"
        :selectedScenario="selectedScenario"
      />
    </DetailList>

    <EditMeasureModal
      v-if="addNew"
      :gridOptions="gridOptions"
      :building="building"
      :portfolio="portfolio"
      :selectedScenario="selectedScenario"
      @close="onAddClose"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import ButtonWrapper from '@/components/shared/ButtonWrapper.vue'
import ScenarioPicker from '@/components/shared/ScenarioPicker.vue'
import DetailList from '@/components/shared/lists/DetailList.vue'
import MeasureRow from '@/components/building/measures/MeasureRow.vue'
import EditMeasureModal from '@/components/building/measures/edit/EditMeasureModal.vue'

export default {
  components: {
    ButtonWrapper,
    DetailList,
    MeasureRow,
    ScenarioPicker,
    EditMeasureModal,
  },

  props: {
    portfolio: {
      type: Object,
    },
    building: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      addNew: false,
      selectedScenario: null,
    }
  },

  computed: {
    ...mapGetters({
      getBuildingPermission: 'permissions/getBuildingPermission',
    }),

    measures() {
      return (
        this.selectedScenario &&
        this.building.measures.filter(
          (m) => ['PLANNED', 'COMPLETED'].includes(m.phase) || m.scenario_id === this.selectedScenario.id
        )
      )
    },

    gridOptions() {
      return this.portfolio.heat_grids.map((g) => {
        return {
          id: g.identifier,
          label: g.name,
        }
      })
    },
  },

  methods: {
    onAdd() {
      this.addNew = true
    },

    onAddClose() {
      this.addNew = false
    },
  },
}
</script>

<style lang="scss" scoped>
.c-measures-list {
  & .button-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & .scenario-picker {
      margin-bottom: 0;
    }
  }
}
</style>

<style lang="scss">
.c-measures-list {
  & > .detail-list {
    overflow: hidden;

    & > ul > li {
      display: grid;
      grid-template-columns: 120px 40px 1fr 140px 90px 90px 180px;
      align-items: start;
    }
  }
}

@media screen and (min-width: 52.9rem) {
  .c-measures-list {
    grid-column: 1 / -1;
  }
}
</style>
