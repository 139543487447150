<i18n>
{
  "de": {
    "modalTitle": "Raumwärmebedarf",
    "rhCalibrationLabel": "Kalibrierungsfaktor",
    "rhLimitLabel": "Grenzwert SIA 380/1",
    "rhTotalLabel": "Nutzenergie",
    "rhEndEnergyLabel": "Endenergie",
    "histogramTitle": "Im Vergleich mit anderen Liegenschaften",
    "rhInfoBoxTitle": "Nutzenergie Raumwärme",
    "rhInfoBox": "Nutzenergiebedarf Raumwärme unter Berücksichtigung des Kalibrierungsfaktors.",
    "rhEndEnergyInfoBoxTitle": "Endenergie Raumwärme",
    "rhEndEnergyInfoBox": "Endenergiebedarf Raumwärme (netto gelieferte Energie, z.B. Brennwert von Heizöl) unter Berücksichtigung des Kalibrierungsfaktors. Die Differenz zur Nutzenergie entsteht durch die Nutzungsziffer des Wärmeerzeugers.",
    "calibrationInfoBox": "Abweichung des effektiven Raumwärmebedarfs vom modellierten Raumwärmebedarf. Dieser Wert wird aus der Kalibrierung des Endenergiebedarfs der Wärmeerzeuger ermittelt",
    "calibrationInfoBoxTitle": "Kalibrierungsfaktor Nutzenergie Raumwärme",
    "highCalibrationFactorWarningTitle": "Effektiver Energieverbrauch höher als erwartet",
    "highCalibrationFactorWarning": "Der effektive Energieverbrauch liegt deutlich über dem modellierten Energiebedarf. Für mögliche Ursachen siehe Kalibrierung Wärmeerzeugung.",
    "lowCalibrationFactorWarningTitle": "Effektiver Energieverbrauch tiefer als erwartet",
    "lowCalibrationFactorWarning": "Der effektive Energieverbrauch liegt deutlich unter dem modellierten Energiebedarf. Für mögliche Ursachen siehe Kalibrierung Wärmeerzeugung."
  }
}
</i18n>

<template>
  <Modal class="rh-demand-modal" wide @close="$emit('close', $event)">
    <template #header>
      <h2>{{ $t('modalTitle') }}</h2>
      <h3>{{ building.identifier }} / {{ building.description }}</h3>
    </template>
    <div class="current-kpis">
      <DetailList>
        <DetailListItem :title="$t('rhTotalLabel')" :info-title="$t('rhInfoBoxTitle')" :info-text="$t('rhInfoBox')">
          <div class="three-columns">
            <span>{{ formatNumber(building.kpi.rh_demand / 1000, 1) }} MWh</span>
            <span>{{ formatNumber(building.kpi.rh_demand / building.kpi.energy_area, 0) }} kWh/m²</span>
            <span>{{ $t('rhLimitLabel') }}: {{ formatNumber(building.kpi.rh_limit, 1) }} kWh/m²</span>
          </div>
          <template #rightContent>
            <EnergyLabel :value="rhRatio" class="energy-label" />
          </template>
        </DetailListItem>
        <DetailListItem
          :title="$t('rhEndEnergyLabel')"
          :info-title="$t('rhEndEnergyInfoBoxTitle')"
          :info-text="$t('rhEndEnergyInfoBox')"
        >
          <div class="two-columns">
            <span>{{ formatNumber(building.kpi.rh_end_energy / 1000, 1) }} MWh</span>
            <span>{{ formatNumber(building.kpi.rh_end_energy / building.kpi.energy_area, 0) }} kWh/m²</span>
          </div>
        </DetailListItem>
        <DetailListItem
          v-if="building.kpi.rh_calibration !== 1.0"
          :title="$t('rhCalibrationLabel')"
          :info-title="$t('calibrationInfoBoxTitle')"
          :info-text="$t('calibrationInfoBox')"
        >
          <div v-if="building.kpi.rh_calibration >= 1.0" class="value">
            <span>+{{ formatNumber(building.kpi.rh_calibration * 100 - 100, 1) }} %</span>
            <DataWarning v-if="building.kpi.rh_calibration > 1.667" :title="$t('highCalibrationFactorWarningTitle')">
              <span v-html="$t('highCalibrationFactorWarning')" />
            </DataWarning>
          </div>
          <div v-else class="value">
            <span>{{ formatNumber(building.kpi.rh_calibration * 100 - 100, 1) }} %</span>
            <DataWarning v-if="building.kpi.rh_calibration < 0.6" :title="$t('lowCalibrationFactorWarningTitle')">
              <span v-html="$t('lowCalibrationFactorWarning')" />
            </DataWarning>
          </div>
        </DetailListItem>
      </DetailList>
      <div>
        <h3>{{ $t('histogramTitle') }}</h3>
        <HistogramChart
          :key="'rh_histogram'"
          :group-by-key="'rh_demand'"
          area-specific
          :highlight-value="building.kpi.rh_demand / building.kpi.energy_area"
          :chart-data="chartData"
          class="histogram-chart"
        />
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/shared/Modal.vue'
import EnergyLabel from '@/components/shared/EnergyLabel.vue'
import DataWarning from '@/components/shared/DataWarning.vue'
import DetailList from '@/components/shared/lists/DetailList.vue'
import DetailListItem from '@/components/shared/lists/DetailListItem.vue'
import HistogramChart from '@/components/portfolio/kpi/charts/HistogramChart.vue'

export default {
  components: {
    Modal,
    EnergyLabel,
    DataWarning,
    DetailList,
    DetailListItem,
    HistogramChart,
  },

  props: {
    portfolio: {
      type: Object,
      required: true,
    },
    building: {
      type: Object,
      required: true,
    },
    chartData: {
      type: Array,
      required: true,
    },
  },

  computed: {
    rhRatio() {
      return this.building.kpi.rh_demand / this.building.kpi.energy_area / this.building.kpi.rh_limit
    },
  },
}
</script>

<style lang="scss" scoped>
.rh-demand-modal {
  & .current-kpis {
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-gap: var(--spacing-m);
  }
  & .histogram-chart {
    height: 220px;
  }

  & .value {
    display: flex;
    flex-direction: row;
    align-items: center;

    & > * + * {
      margin-left: var(--spacing-xs);
    }
  }
}
</style>
