<template>
  <GenericButton :className="className" :disabled="disabled" :href="href" @click="onClick">
    <Icon v-if="icon" :icon="icon" :title="iconTitle" />
    <span v-if="text">{{ text }}</span>
  </GenericButton>
</template>

<script>
import GenericButton from '@/components/cui/inputs/GenericButton.vue'
import Icon from '@/components/cui/data-display/Icon.vue'

export default {
  components: {
    GenericButton,
    Icon,
  },

  props: {
    className: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    href: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    iconTitle: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
  },

  methods: {
    onClick() {
      if (this.href) this.$router.push(this.href)
      else this.$emit('click')
    },
  },
}
</script>

<style lang="scss" scoped>
.c-button {
  & .button {
    display: flex;
    align-items: center;
    gap: 4px;
  }
}
</style>
