<i18n>
{
  "de": {
    "addAddressButton": "Adresse hinzufügen",
    "confirmDeleteAddress": "Wollen Sie diese Adresse wirklich löschen?",
    "addEgidButton": "Gebäude hinzufügen",
    "confirmDeleteEgid": "Wollen Sie dieses Gebäude wirklich löschen?",
    "municipalityTitle": "Gemeinde",
    "zipTitle": "Postleitzahl",
    "streetTitle": "Strasse / Nr",
    "egidTitle": "EGID",
    "latitudeTitle": "Latitude",
    "longitudeTitle": "Longitude",
    "byEgidTitle": "Lokalisierung über EGID",
    "byAddressTitle": "Lokalisierung über Adresse",
    "byCoordinatesTitle": "Lokalisierung über Koordinaten",
    "noEgid": "Keine EGID vorgegeben",
    "noAddress": "Keine Adresse vorgegeben"
  }
}
</i18n>

<template>
  <EditContainer
    :bind-form-state="formState"
    :allow-edit="getBuildingPermission('EDIT_LOCATION')"
    class="edit-address"
    @container="onFormEvent"
  >
    <div v-if="isEditing">
      <input id="location-egid" v-model="locationMethod" type="radio" value="EGID" />
      <label for="location-egid">{{ $t('byEgidTitle') }}</label>
      <br />
      <input id="location-address" v-model="locationMethod" type="radio" value="ADDRESS" />
      <label for="location-address">{{ $t('byAddressTitle') }}</label>
      <br />
      <input id="location-coordinates" v-model="locationMethod" type="radio" value="COORDINATES" />
      <label for="location-coordinates">{{ $t('byCoordinatesTitle') }}</label>
    </div>

    <div v-if="locationMethod === 'EGID'">
      <li v-if="model.egids.length === 0">{{ $t('noEgid') }}</li>
      <DetailList v-else has-header class="egid-table">
        <template #header>
          <span></span>
          <span>{{ $t('egidTitle') }}</span>
          <span v-if="isEditing"></span>
        </template>
        <li v-for="(e, index) in model.egids" :key="index">
          <div v-if="building.gwr.egids_success">
            <img v-if="building.gwr.egids_success[index]" src="/icons/check.svg" />
            <img v-else src="/icons/x.svg" />
          </div>
          <TextInput v-model="e.egid" :edit="isEditing" @validation="onValidation($event, 'egid')"/>
          <ListButtonWrapper>
            <ListDeleteButton v-if="isEditing" @click="onDeleteEgid(index)" />
          </ListButtonWrapper>
        </li>
      </DetailList>
      <ButtonWrapper>
        <button v-if="isEditing" class="button" @click.prevent="onAddEgid">{{ $t('addEgidButton') }}</button>
      </ButtonWrapper>
    </div>

    <div v-if="locationMethod === 'ADDRESS'">
      <FormRow :label="$t('municipalityTitle')">
        <TextInput v-model="model.municipality" :edit="isEditing" :allow-empty="true" @validation="onValidation($event, 'municipality')" />
      </FormRow>
      <FormRow :label="$t('zipTitle')">
        <NumericInput v-model="model.zip_code" :edit="isEditing" :min="1000" :max="9999" noFormatting @validation="onValidation($event, 'zip_code')" />
      </FormRow>
      <li v-if="model.addresses.length === 0">{{ $t('noAddress') }}</li>
      <DetailList v-else has-header class="address-table">
        <template #header>
          <span></span>
          <span>{{ $t('streetTitle') }}</span>
          <span v-if="isEditing"></span>
        </template>
        <li v-for="(a, index) in model.addresses" :key="index">
          <div v-if="building.gwr.addresses_success">
            <img v-if="building.gwr.addresses_success[index]" src="/icons/check.svg" />
            <img v-else src="/icons/x.svg" />
          </div>
          <TextInput v-model="a.address" :edit="isEditing" :allow-empty="false" @validation="onValidation($event, 'address')" />
          <ListButtonWrapper v-if="isEditing">
            <ListDeleteButton @click="onDeleteAddress(index)" />
          </ListButtonWrapper>
        </li>
      </DetailList>
      <ButtonWrapper>
        <button v-if="isEditing" class="button" @click.prevent="onAddAddress">{{ $t('addAddressButton') }}</button>
      </ButtonWrapper>
    </div>

    <div v-if="locationMethod === 'COORDINATES'">
      <FormRow :label="$t('latitudeTitle')">
        <NumericInput v-model="model.latitude" noFormatting :min="-90" :max="90" :edit="isEditing" :units="'°'" @validation="onValidation($event, 'latitude')" />
      </FormRow>
      <FormRow :label="$t('longitudeTitle')">
        <NumericInput v-model="model.longitude" noFormatting :min="-180" :max="180" :edit="isEditing" :units="'°'" @validation="onValidation($event, 'longitude')" />
      </FormRow>
    </div>
  </EditContainer>
</template>

<script>
import { mapActions } from 'vuex'

import ApiFormMixin from '@/components/shared/forms/ApiFormMixin.vue'

import EditContainer from '@/components/shared/forms/EditContainer.vue'
import ListButtonWrapper from '@/components/shared/lists/ListButtonWrapper.vue'
import ListDeleteButton from '@/components/shared/lists/ListDeleteButton.vue'
import ButtonWrapper from '@/components/shared/ButtonWrapper.vue'
import DetailList from '@/components/shared/lists/DetailList.vue'
import TextInput from '@/components/shared/forms/TextInput.vue'
import NumericInput from '@/components/shared/forms/NumericInput.vue'
import FormRow from '@/components/shared/forms/FormRow.vue'

export default {
  mixins: [
    ApiFormMixin,
    /* ApiFormMixin:
      - Requires implementation of the following methods: resetModel, saveHandler, isValid (optional)
      - Provides: model, isValid, isEditing, formState, onFormEvent, onValidation,
                  getBuildingPermission, getPortfolioPermission
    */
  ],

  components: {
    EditContainer,
    ListDeleteButton,
    ListButtonWrapper,
    ButtonWrapper,
    FormRow,
    TextInput,
    NumericInput,
    DetailList,
  },

  props: {
    building: {
      type: Object,
    },
  },

  data() {
    return {
      locationMethod: null,
    }
  },

  computed: {
    isValid() {
      if (this.locationMethod === 'EGID') {
        return this.model.egids.length > 0 && !this.invalidFields.includes('egid')
      } else if (this.locationMethod === 'COORDINATES') {
        return (!this.invalidFields.includes('latitude') && !this.invalidFields.includes('longitude'))
      } else {
        return (this.model.addresses.length > 0 && !this.invalidFields.includes('zip_code') && !this.invalidFields.includes('address'))
      }
    },
  },

  mounted() {
    if (this.model.egids.length > 0) {
      this.locationMethod = 'EGID'
    } else if (this.model.addresses.length > 0) {
      this.locationMethod = 'ADDRESS'
    } else if (this.model.latitude !== null && this.model.longitude !== null) {
      this.locationMethod = 'COORDINATES'
    } else {
      this.locationMethod = 'ADDRESS'
    }
  },

  methods: {
    ...mapActions({
      updateBuilding: 'building/updateBuilding',
    }),

    resetModel() {
      this.model = {
        municipality: this.building.municipality,
        zip_code: this.building.zip_code,
        latitude: this.building.latitude,
        longitude: this.building.longitude,
        addresses:
          this.building.addresses === null
            ? []
            : this.building.addresses.map((a) => {
                return {
                  address: a,
                }
              }),
        egids:
          this.building.egids === null
            ? []
            : this.building.egids.map((e) => {
                return {
                  egid: e,
                }
              }),
      }
    },

    async saveHandler(model) {
      if (this.locationMethod === 'ADDRESS') {
        model.egids = []
        model.latitude = null
        model.longitude = null
      } else if (this.locationMethod === 'EGID') {
        model.addresses = []
        model.latitude = null
        model.longitude = null
      } else {
        model.addresses = []
        model.egids = []
      }
      await this.updateBuilding({
        id: this.building.id,
        municipality: model.municipality,
        zip_code: model.zip_code,
        addresses: model.addresses.length > 0 ? model.addresses.map((a) => a.address).join(';') : null,
        egids: model.egids.length > 0 ? model.egids.map((e) => e.egid).join(';') : null,
        latitude: model.latitude,
        longitude: model.longitude,
      })
    },

    onAddAddress() {
      this.model.addresses.push({
        address: '',
      })
    },

    onDeleteAddress(index) {
      if (confirm(this.$t('confirmDeleteAddress'))) {
        this.model.addresses.splice(index, 1)
      }
    },

    onAddEgid() {
      this.model.egids.push({
        egid: '',
      })
    },

    onDeleteEgid(index) {
      if (confirm(this.$t('confirmDeleteEgid'))) {
        this.model.egids.splice(index, 1)
      }
    },
  },
}
</script>

<style>
.edit-address .address-table.detail-list > ul > li {
  grid-template-columns: max-content 1fr max-content;
}
.edit-address .egid-table.detail-list > ul > li {
  grid-template-columns: max-content 1fr max-content;
}
</style>
