<i18n>
{
  "de": {
    "designationHeader": "Bezeichnung"
  }
}
</i18n>

<template>
  <DetailListItem :title="$t('designationHeader')" expandable @expand="onModalOpen">
    <div class="item-content">
      <span>{{ building.description }}</span
      ><span>{{ building.identifier }}</span>
    </div>
    <template #modal>
      <Modal v-if="isModalOpen" @close="onModalClose">
        <template #header>
          <h2>{{ $t('designationHeader') }}</h2>
        </template>
        <EditBaseData :building="building" />
      </Modal>
    </template>
  </DetailListItem>
</template>

<script>
import ModalMixin from '@/components/shared/ModalMixin.vue'

import Modal from '@/components/shared/Modal.vue'
import EditBaseData from '@/components/building/edit/EditBaseData.vue'
import DetailListItem from '@/components/shared/lists/DetailListItem.vue'

export default {
  mixins: [ModalMixin],

  components: {
    Modal,
    EditBaseData,
    DetailListItem,
  },

  props: {
    building: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.item-content {
  display: flex;
  gap: 16px;

  & span:nth-child(2) {
    color: var(--secondary-text-color);
  }
}
</style>
