<i18n>
{
  "de": {
    "metersTitle": "Verbrauchsdaten",
    "noMeters": "Diese Liegenschaft hat noch keine Verbrauchsdatenpunkte.",
    "meterTypeTitle": "Typ",
    "meterCarrierTitle": "Energieträger",
    "meterIdentifierTitle": "Datenpunkt Nr. (optional)",
    "startMonthTitle": "Stichmonat",
    "months": {
      "1": "1 (Januar)",
      "2": "2 (Februar)",
      "3": "3 (März)",
      "4": "4 (April)",
      "5": "5 (Mai)",
      "6": "6 (Juni)",
      "7": "7 (Juli)",
      "8": "8 (August)",
      "9": "9 (September)",
      "10": "10 (Oktober)",
      "11": "11 (November)",
      "12": "12 (Dezember)"
    },
    "addMeterButton": "Datenpunkt hinzufügen",
    "confirmDeleteMeter": "Wollen Sie diesen Datenpunkt und alle erfasste Werte wirklich löschen?",
    "valuesTitle": "Erfasste Werte",
    "valuesCountTitle": "{count} Jahr | {count} Jahre",
    "noValues": "Keine Werte",
    "sumTitle": "Summe",
    "amountNormalizedTitle": "Endenergie (witterungsbereinigt)",
    "ghgEmissionsS1Title": "Scope 1 Emissionen",
    "ghgEmissionsTitle": "Scope 1-2 Emissionen",
    "peDemandTitle": "Primärenergie",
    "normalizationTitle": "Witterungsbereinigung",
    "normalizationSource": "Methode: HGT20/12 (HEV Schweiz / MeteoSchweiz)",
    "normalizationStation": "Klimastation: Zürich-MeteoSchweiz (SMA)",
    "altitudeLabel": "Höhe: 556 m",
    "normTempLabel": "Normtemperatur: °C"
  }
}
</i18n>

<template>
  <div class="c-meters-list">
    <div class="title">
      <h2>{{ $t('metersTitle') }}</h2>
      <ButtonWrapper>
        <button
          v-if="getBuildingPermission('EDIT_METERS')"
          type="button"
          class="button"
          @click="onEditMeterModalOpen(null)"
        >
          {{ $t('addMeterButton') }}
        </button>

        <ListDetailsButton v-if="Boolean(building.meters.length)" @click="onMetersChartModalOpen" />
      </ButtonWrapper>
    </div>

    <NoDataMessage v-if="!Boolean(building.meters.length)" :message="$t('noMeters')" />

    <DetailList v-else has-header :has-footer="building.meters.length > 0" class="meters-table">
      <!-- Header -->
      <template #header>
        <span>{{ $t('meterTypeTitle') }}</span>
        <span>{{ $t('meterCarrierTitle') }}</span>
        <span>{{ $t('meterIdentifierTitle') }}</span>
        <span>{{ $t('startMonthTitle') }}</span>
        <span>{{ $t('valuesTitle') }}</span>
        <span>
          <span class="align-right">{{ $t('amountNormalizedTitle') }}</span>
          <InfoBox :title="$t('normalizationTitle')">
            {{ $t('normalizationSource') }}<br />
            {{ $t('normalizationStation') }}<br />
            {{ $t('altitudeLabel') }}<br />
            {{ $t('normTempLabel') }}<br />
          </InfoBox>
        </span>
        <span class="align-right">{{ $t('ghgEmissionsS1Title') }}</span>
        <span class="align-right">{{ $t('ghgEmissionsTitle') }}</span>
        <span class="align-right">{{ $t('peDemandTitle') }}</span>
        <span></span>
      </template>

      <!-- Meters -->
      <li v-for="meter in meters" :key="meter.id">
        <!-- Meter type -->
        <div>
          {{ meter.meter_type ? $t(`_meterTypes.${meter.meter_type}`) : meter.meter_type }}
        </div>

        <!-- Meter energy carrier -->
        <div>
          <div v-if="meter.meter_type === 'END_ENERGY_HEATER'">
            {{ energyCarrierIdentifier(meter.energy_carrier_id) }}
          </div>
          <div v-else>-</div>
        </div>

        <!-- Meter identifier -->
        <div>{{ meter.identifier || '-' }}</div>

        <!-- Meter starting month -->
        <div>{{ $t(`months.${meter.start_month}`) }}</div>

        <!-- Number of values / average -->
        <div v-if="meter.values.length">
          {{ $tc('valuesCountTitle', meter.values.length, { count: meter.values.length }) }}
        </div>
        <div v-else>{{ $t('noValues') }}</div>

        <!-- End energy -->
        <div v-if="meter.values.length" class="align-right">
          {{ formatNumber(averageAmountNormalized(meter) / 1000, 1) }} MWh
        </div>
        <div v-else class="align-right">-</div>

        <!-- Scope 1 emissions -->
        <div v-if="meter.values.length" class="align-right">
          {{ formatNumber(averageGhgS1(meter) / 1000, 1) }} t CO₂e
        </div>
        <div v-else class="align-right">-</div>

        <!-- Scope 2 emissions -->
        <div v-if="meter.values.length" class="align-right">{{ formatNumber(averageGhg(meter) / 1000, 1) }} t CO₂e</div>
        <div v-else class="align-right">-</div>

        <!-- Primary energy -->
        <div v-if="meter.values.length" class="align-right">{{ formatNumber(averagePe(meter) / 1000, 1) }} MWh</div>
        <div v-else class="align-right">-</div>

        <!-- Actions -->
        <ListButtonWrapper>
          <ListEditButton :viewIcon="!getBuildingPermission('EDIT_METERS')" @click="onEditMeterModalOpen(meter)" />
          <ListDeleteButton v-if="getBuildingPermission('EDIT_METERS')" @click="onDeleteMeter(meter)" />
        </ListButtonWrapper>
      </li>

      <template #footer>
        <span>{{ $t('sumTitle') }}</span>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <span class="align-right">{{ formatNumber(sumAmountNormalized / 1000, 1) }} MWh</span>
        <span class="align-right">{{ formatNumber(sumGhgS1 / 1000, 1) }} t CO₂e</span>
        <span class="align-right">{{ formatNumber(sumGhg / 1000, 1) }} t CO₂e</span>
        <span class="align-right">{{ formatNumber(sumPe / 1000, 1) }} MWh</span>
        <div></div>
      </template>
    </DetailList>
    <ListActionFeedback :message="error" @close="error = null" />

    <MetersChartModal
      v-if="metersChartModalOpen"
      :portfolio="portfolio"
      :building="building"
      @close="onMetersChartModalClose"
    />

    <EditMeterModal
      v-if="editMeterModalOpen"
      :portfolio="portfolio"
      :building="building"
      :meter-id="editMeterId"
      @add="onAddMeter"
      @close="onEditMeterModalClose"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import ButtonWrapper from '@/components/shared/ButtonWrapper.vue'
import ListButtonWrapper from '@/components/shared/lists/ListButtonWrapper.vue'
import ListEditButton from '@/components/shared/lists/ListEditButton.vue'
import ListDetailsButton from '@/components/shared/lists/ListDetailsButton.vue'
import ListDeleteButton from '@/components/shared/lists/ListDeleteButton.vue'
import DetailList from '@/components/shared/lists/DetailList.vue'
import ListActionFeedback from '@/components/shared/lists/ListActionFeedback.vue'
import NoDataMessage from '@/components/shared/lists/NoDataMessage.vue'
import EditMeterModal from '@/components/building/meters/EditMeterModal.vue'
import MetersChartModal from '@/components/building/meters/MetersChartModal.vue'
import InfoBox from '@/components/shared/InfoBox.vue'

export default {
  components: {
    ButtonWrapper,
    ListButtonWrapper,
    ListEditButton,
    ListDeleteButton,
    ListDetailsButton,
    DetailList,
    ListActionFeedback,
    NoDataMessage,
    EditMeterModal,
    MetersChartModal,
    InfoBox,
  },

  props: {
    portfolio: {
      type: Object,
      required: true,
    },
    building: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      metersChartModalOpen: false,
      editMeterId: null,
      editMeterModalOpen: false,
      error: null,
    }
  },

  computed: {
    ...mapGetters({
      getBuildingPermission: 'permissions/getBuildingPermission',
    }),

    // Sort by meter type, then identifier
    meters() {
      return this.building.meters.slice().sort((a, b) => {
        return a.meter_type.localeCompare(b.meter_type)
      })
    },

    sumAmountNormalized() {
      const sum = this.building.meters.reduce((pv, cv) => pv + this.averageAmountNormalized(cv), 0)
      return sum
    },

    sumGhgS1() {
      const sum = this.building.meters.reduce((pv, cv) => pv + this.averageGhgS1(cv), 0)
      return sum
    },

    sumGhg() {
      const sum = this.building.meters.reduce((pv, cv) => pv + this.averageGhg(cv), 0)
      return sum
    },

    sumPe() {
      const sum = this.building.meters.reduce((pv, cv) => pv + this.averagePe(cv), 0)
      return sum
    },
  },

  methods: {
    ...mapActions({
      deleteMeter: 'building/deleteMeter',
    }),

    //
    energyCarrierIdentifier(energyCarrierId) {
      const energyCarrier = this.portfolio.energy_carriers.find((ec) => ec.id === energyCarrierId)
      if (energyCarrier) {
        if (energyCarrier.default) {
          return this.$t(`_energyCarriers.${energyCarrier.name}`)
        } else {
          return energyCarrier.name
        }
      } else {
        return this.$t('_energyCarriers.UNKNOWN')
      }
    },

    //
    averageAmountNormalized(meter) {
      if (meter.values.length === 0) {
        return null
      }
      const sum = meter.values.reduce((pv, cv) => pv + cv.amount_normalized, 0)
      return sum / meter.values.length
    },

    //
    averageGhgS1(meter) {
      if (meter.values.length === 0) {
        return null
      }
      const sum = meter.values.reduce((pv, cv) => pv + cv.s1e, 0)
      return sum / meter.values.length
    },

    //
    averageGhg(meter) {
      if (meter.values.length === 0) {
        return null
      }
      const sum = meter.values.reduce((pv, cv) => pv + cv.s12e, 0)
      return sum / meter.values.length
    },

    //
    averagePe(meter) {
      if (meter.values.length === 0) {
        return null
      }
      const sum = meter.values.reduce((pv, cv) => pv + cv.pe, 0)
      return sum / meter.values.length
    },

    //
    onMetersChartModalOpen() {
      this.metersChartModalOpen = true
    },

    //
    onMetersChartModalClose() {
      this.metersChartModalOpen = false
    },

    //
    onEditMeterModalOpen(meter) {
      this.error = null
      if (meter) {
        this.editMeterId = meter.id
      } else {
        this.editMeterId = null
      }
      this.editMeterModalOpen = true
    },

    //
    onEditMeterModalClose() {
      this.editMeterModalOpen = false
    },

    // Update currently edited meter id
    onAddMeter(result) {
      this.editMeterId = result?.id || null
    },

    //
    onDeleteMeter(meter) {
      this.error = null
      setTimeout(async () => {
        if (confirm(this.$t('confirmDeleteMeter'))) {
          try {
            await this.deleteMeter({ id: meter.id, building_id: meter.building_id })
          } catch (error) {
            this.error = error
          }
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.c-meters-list {
  overflow: hidden;

  & .title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & .button-wrapper {
      gap: var(--spacing-s);
    }
  }

  & .normalization-hint {
    padding: var(--spacing-s);
    border: var(--box-border);
    border-radius: var(--box-radius);
  }

  & .align-right {
    text-align: right;
    justify-content: flex-end;
  }
}
</style>
