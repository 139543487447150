<i18n>
{
  "de": {
    "elHeader": "Photovoltaik"
  }
}
</i18n>

<template>
  <DetailListItem class="photovoltaic-summary-row" :title="$t('elHeader')" expandable @expand="onModalOpen">
    <div class="photovoltaic-summary-contents">
      <span v-if="photovoltaicSummary">{{ photovoltaicSummary }}</span>
    </div>

    <template #modal>
      <Modal v-if="isModalOpen" wide @close="onModalClose">
        <template #header>
          <h2>{{ $t('elHeader') }}</h2>
        </template>
        <EditPv :building="building" />
      </Modal>
    </template>
  </DetailListItem>
</template>

<script>
import ModalMixin from '@/components/shared/ModalMixin.vue'

import Modal from '@/components/shared/Modal.vue'
import DetailListItem from '@/components/shared/lists/DetailListItem.vue'
import EditPv from '@/components/building/edit/EditPv.vue'

export default {
  mixins: [ModalMixin],

  components: {
    Modal,
    DetailListItem,
    EditPv,
  },

  props: {
    building: {
      type: Object,
      required: true,
    },
  },

  computed: {
    photovoltaicSummary() {
      const photovoltaicSummaryElement = this.building.initial_state.pv.sections.reduce((c, pv) => c + pv.capacity, 0)

      return photovoltaicSummaryElement ? `${photovoltaicSummaryElement} kWp` : '-'
    },
  },
}
</script>
