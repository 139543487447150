<script>
import BaseLayer from '@/components/building/map/BaseLayer.vue'

export default {
  components: {
    BaseLayer,
  },

  extends: BaseLayer,

  computed: {
    sourceConfig() {
      let features = []
      if (this.building.roof.state === 'OK') {
        let tileIndex = 0
        const tiles = this.building.roof.roof_tiles
        for (let i = 0; i < tiles.length; i++) {
          const tile = tiles[i]
          const feasibilityTile = this.building.feasibility.roof_tiles.find((t) => t.id === tile.id)
          features.push({
            id: tileIndex,
            type: 'Feature',
            geometry: tile['geometry'],
            properties: { ...tile, pv_clustering_list: feasibilityTile ? feasibilityTile.pv_clustering_list : 'NONE' },
          })
          tileIndex++
        }
      }
      return {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: features,
        },
      }
    },

    layerConfig() {
      return {
        type: 'fill',
        source: this.sourceId,
        paint: {
          'fill-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 1, 0.5],
          'fill-outline-color': '#555555',
          'fill-color': [
            'match',
            ['get', 'pv_clustering_list'],
            'WHITE',
            '#fff',
            'BLACK',
            '#000',
            /* other */
            '#888',
          ],
        },
      }
    },
  },
}
</script>
