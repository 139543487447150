<i18n>
{
  "de": {
    "modalTitle": "Renovationsstandards",
    "modalDescription": "Erlaubt das Erstellen von Renovationsstandards spezifisch für die aktuelle Liegenschaft.",
    "addButton": "Renovationsstandard hinzufügen",
    "nameTitle": "Name",
    "editButton": "Bearbeiten",
    "pendingMessage": "Daten werden gespeichert...",
    "confirmDelete": "Wollen Sie diesen Renovationsstandard wirklich löschen?",
    "noRecords": "Für diese Liegenschaft sind noch keine Renovationsstandards vorhanden.",
    "defaultDeletionDialog": "Renovationsstandardvorlagen können nicht gelöscht werden"
  }
}
</i18n>

<template>
  <Modal wide class="renovation-standards-modal" @close="$emit('close', $event)">
    <template #header>
      <h2>{{ $t('modalTitle') }}</h2>
      <p>{{ $t('modalDescription') }}</p>
      <br />
    </template>

    <div v-if="error">
      <p>{{ error }}</p>
    </div>

    <div v-if="pending">
      <p>{{ $t('pendingMessage') }}</p>
    </div>
    <div v-else-if="!editMode">
      <div v-if="buildingRenovationStandards.length === 0">
        {{ $t('noRecords') }}
      </div>
      <DetailList v-else has-header class="standards-table">
        <template #header>
          <span>{{ $t('nameTitle') }}</span>
          <span></span>
        </template>
        <li
          v-for="(renovation_standard, index) in buildingRenovationStandards"
          :key="index"
          class="renovation-standard-row"
        >
          <span>{{ renovation_standard.name }}</span>
          <ListButtonWrapper class="align-right">
            <ListEditButton
              v-if="getBuildingPermission('EDIT_RENOVATION_STANDARDS')"
              @click="onEditModeOpen(renovation_standard)"
            />
            <ListDeleteButton
              v-if="getBuildingPermission('EDIT_RENOVATION_STANDARDS')"
              :disabled="renovation_standard.default"
              @click="onDelete(renovation_standard)"
            />
          </ListButtonWrapper>
        </li>
      </DetailList>
      <ButtonWrapper>
        <a
          v-if="getBuildingPermission('EDIT_RENOVATION_STANDARDS')"
          class="button"
          href="#"
          @click.prevent="onEditModeOpen(null)"
        >
          {{ $t('addButton') }}
        </a>
      </ButtonWrapper>
    </div>
    <EditRenovationStandard
      v-if="editMode"
      :portfolio="portfolio"
      :renovation-standard="editStandard"
      :building="building"
      @save="onEditModeClose"
      @cancel="onEditModeClose"
      @error="onError"
    />
  </Modal>
</template>

<script>
import Modal from '@/components/shared/Modal.vue'
import ListButtonWrapper from '@/components/shared/lists/ListButtonWrapper.vue'
import ListDeleteButton from '@/components/shared/lists/ListDeleteButton.vue'
import ListEditButton from '@/components/shared/lists/ListEditButton.vue'
import ButtonWrapper from '@/components/shared/ButtonWrapper.vue'
import DetailList from '@/components/shared/lists/DetailList.vue'
import EditRenovationStandard from '@/components/settings/renovation_standards/EditRenovationStandard.vue'

import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    Modal,
    ListButtonWrapper,
    ListEditButton,
    ListDeleteButton,
    ButtonWrapper,
    DetailList,
    EditRenovationStandard,
  },

  props: {
    building: {
      type: Object,
      required: true,
    },
    portfolio: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      error: null,
      pending: false,
      editStandard: null,
      editMode: false,
    }
  },

  computed: {
    ...mapGetters({
      getPortfolioPermission: 'permissions/getPortfolioPermission',
      getBuildingPermission: 'permissions/getBuildingPermission',
    }),

    buildingRenovationStandards() {
      return this.portfolio.renovation_standards.filter((rs) => rs.building_id === this.building.id)
    },
  },

  methods: {
    ...mapActions({
      deleteRenovationStandard: 'portfolio/deleteRenovationStandard',
    }),

    onEditModeOpen(editStandard) {
      this.editStandard = editStandard
      this.editMode = true
    },

    onEditModeClose() {
      this.editMode = false
      this.editStandard = null
    },

    async onDelete(renovationStandard) {
      if (!renovationStandard.default) {
        if (confirm(this.$t('confirmDelete'))) {
          this.pending = true
          try {
            this.error = null
            await this.deleteRenovationStandard(renovationStandard)
          } catch (error) {
            this.error = error
          }
          this.pending = false
        }
      }
    },

    onError(error) {
      this.error = error
    },
  },
}
</script>

<style>
.standards-table .align-right {
  text-align: right;
  justify-content: flex-end;
}
</style>
