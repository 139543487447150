<template>
  <span>
    <VPopover trigger="hover" placement="right">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <circle cx="12" cy="12" r="10"></circle>
        <line x1="12" y1="8" x2="12" y2="12"></line>
        <line x1="12" y1="16" x2="12.01" y2="16"></line>
      </svg>
      <template slot="popover">
        <header v-if="title">{{ title }}</header>
        <main>
          <slot></slot>
        </main>
      </template>
    </VPopover>
  </span>
</template>

<script>
import { VPopover } from 'v-tooltip'

export default {
  components: {
    VPopover,
  },

  props: {
    title: {
      type: String,
    },
  },
}
</script>

<style scoped>
.v-popover {
  display: inline-block;
  vertical-align: middle;
  margin: 0 3px 0 3px;
}
</style>
